import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import { BaseplateEntityUserProfileCard } from '../CustomComponents/BaseplateEntityUserProfileCard';
import { entityWarningContent } from '../ContentSections';
import { ProductRelationsTree } from '@lego/plugin-baseplate-core-components';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { RELATION_MEMBER_OF } from '@backstage/catalog-model';

const UserPage = () => {
  const { entity } = useEntity();
  const product = (useRelatedEntities(entity, {
    type: RELATION_MEMBER_OF,
  }).entities ?? [])[0];

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={4}>
          {entityWarningContent}
          <Grid item xs={12} md={6}>
            <BaseplateEntityUserProfileCard variant="gridItem" />
          </Grid>
          {product && (
            <Grid item xs={12} md={6}>
              <ProductRelationsTree showProduct product={product} />
            </Grid>
          )}
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};

export default UserPage;
