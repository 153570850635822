import { ConsumedResponse } from '@backstage/errors';

/**
 * A standard shape of JSON data returned as the body of backend errors.
 *
 * @public
 */
export type AmmaErrorResponseBody = {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  additionalProp1?: string;
  additionalProp2?: string;
  additionalProp3?: string;
};

/**
 * Attempts to construct an AmmaErrorResponseBody out of a failed server request.
 * Assumes that the response has already been checked to be not ok. This
 * function consumes the body of the response, and assumes that it hasn't
 * been consumed before.
 *
 * The code is forgiving, and constructs a useful synthetic body as best it can
 * if the response body wasn't on the expected form.
 *
 * @public
 * @param response - The response of a failed request
 */
export async function parseErrorResponseBody(
  response: ConsumedResponse & { json(): Promise<any> },
): Promise<AmmaErrorResponseBody> {
  try {
    const error = await response.json();
    if (error) {
      return {
        ...error,
      };
    }
  } catch {
    // ignore
  }

  return {
    type: 'Error',
    title: 'Request Failed',
    status: response.status,
    detail: `Request failed with status ${response.status} ${response.statusText}`,
    instance: response.url,
  };
}
