import { ResponseErrorPanel } from '@backstage/core-components';
import {
  appThemeApiRef,
  featureFlagsApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { BannerCreateParams } from '@lego/plugin-baseplate-admin-common';
import { Input } from '@lego/plugin-baseplate-core-components';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  capitalize,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DismissableBanner } from '../DismissableBanner';

interface Props {
  setFormData: (value: React.SetStateAction<BannerCreateParams>) => void;
  formData: BannerCreateParams;
  onSubmit: (event: any) => Promise<void>;
  type: 'Create' | 'Edit';
}

export const BannerForm = ({
  setFormData,
  onSubmit,
  formData,
  type,
}: Props) => {
  const {
    severity,
    text_body,
    theme,
    title,
    link_text,
    link_url,
    pages,
    featureFlag,
  } = formData;
  const appApi = useApi(appThemeApiRef);
  const featureFlagApi = useApi(featureFlagsApiRef);
  const hasLink =
    link_text !== undefined &&
    link_url !== undefined &&
    link_url !== null &&
    link_text !== null;
  const hasTheme = theme !== undefined && theme !== null;
  const hasPages = pages !== undefined && pages !== null && pages.length > 0;

  const themes = appApi.getInstalledThemes();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wantsLink, setWantsLink] = useState(
    type === 'Create' ? false : hasLink,
  );
  const [wantsFeatureFlag, setWantsFeatureFlag] = useState(
    type === 'Create' ? false : featureFlag !== undefined,
  );

  const [wantsTheme, setWantsTheme] = useState(
    type === 'Create' ? false : hasTheme,
  );
  const [wantsPages, setWantsPages] = useState(
    type === 'Create' ? false : hasPages,
  );
  const [formError, setFormError] = useState();

  const handleChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!wantsLink) {
      setFormData(prevFormData => ({
        ...prevFormData,
        link_text: null,
        link_url: null,
      }));
    }
    if (!wantsTheme) {
      setFormData(prevFormData => ({
        ...prevFormData,
        theme: null,
      }));
    }
    if (!wantsPages) {
      setFormData(prevFormData => ({
        ...prevFormData,
        pages: null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsLink, wantsPages, wantsTheme]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setLoading(true);
      await onSubmit(event);
    } catch (error: any) {
      setFormError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Box>
        <DismissableBanner banner={formData} id={`preview-${uuidv4()}`} />
      </Box>
      <form onSubmit={e => void handleSubmit(e)}>
        <FormControl fullWidth margin="dense" size="medium">
          <Input
            fullWidth
            required
            inputProps={{
              maxLength: 100,
            }}
            label="Title"
            name="title"
            value={title}
            onChange={handleChange}
            placeholder="Type the title here..."
            helperText="The title of the banner. Max 100 characters."
          />
        </FormControl>
        <FormControl fullWidth margin="dense" size="medium">
          <Input
            fullWidth
            required
            label="Banner message"
            name="text_body"
            inputProps={{
              maxLength: 250,
            }}
            value={text_body}
            onChange={handleChange}
            placeholder="Type the message here..."
            multiline
            helperText="The message of the banner. Max 250 characters."
          />
        </FormControl>
        <FormControl fullWidth margin="dense" size="medium">
          <Autocomplete
            id="severity"
            options={['info', 'warning', 'error']}
            getOptionLabel={option => capitalize(option)}
            onChange={(_, value) =>
              value &&
              setFormData(prevFormData => ({
                ...prevFormData,
                severity: value as BannerCreateParams['severity'],
              }))
            }
            value={severity}
            renderInput={params => (
              <Input
                {...params}
                label="Select a severity"
                variant="outlined"
                helperText="The severity of the banner. This will determine the color of the banner."
              />
            )}
          />
        </FormControl>
        <FormControlLabel
          onChange={() => setWantsLink(!wantsLink)}
          control={<Checkbox checked={wantsLink} name="link_check" />}
          label="Do you want to add a link?"
        />
        <FormControlLabel
          onChange={() => setWantsFeatureFlag(!wantsFeatureFlag)}
          control={<Checkbox checked={wantsFeatureFlag} name="link_check" />}
          label="Do you want to specify the banner to a feature flag?"
        />
        {wantsFeatureFlag && (
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" size="medium">
              <Autocomplete
                id="featureFlag"
                options={featureFlagApi.getRegisteredFlags().map(f => f.name)}
                value={featureFlag}
                getOptionLabel={option => capitalize(option)}
                onChange={(_, value) =>
                  value &&
                  setFormData(prevFormData => ({
                    ...prevFormData,
                    featureFlag: value,
                  }))
                }
                renderInput={params => (
                  <Input
                    {...params}
                    label="Select a feature flag"
                    variant="outlined"
                    helperText="You can select a feature flag to show the banner on. If unspecified, it's showing on all feature flags."
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
        {wantsLink && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Input
                  fullWidth
                  required={wantsLink}
                  label="Link text"
                  name="link_text"
                  inputProps={{
                    maxLength: 250,
                  }}
                  value={link_text}
                  onChange={handleChange}
                  placeholder="Type the link text here..."
                  helperText="The text that will be displayed on the link (eg. 'Learn more'). Max 250 characters."
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Input
                  fullWidth
                  required={wantsLink}
                  label="Link url"
                  name="link_url"
                  inputProps={{
                    maxLength: 250,
                  }}
                  value={link_url}
                  onChange={handleChange}
                  placeholder="Type the link text here..."
                  helperText="If a relative url is provided (eg. '/capabilities'), it will be
          prefixed with the current domain. If an absolute url is provided
          (eg. 'https://www.google.com'), it will be used as is."
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            onChange={() => setWantsTheme(!wantsTheme)}
            control={<Checkbox checked={wantsTheme} name="theme_check" />}
            label="Do you want to specify the banner to a theme?"
          />
        </Grid>
        {wantsTheme && (
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" size="medium">
              <Autocomplete
                id="theme"
                options={themes.map(t => t.id)}
                value={theme}
                getOptionLabel={option => capitalize(option)}
                onChange={(_, value) =>
                  value &&
                  setFormData(prevFormData => ({
                    ...prevFormData,
                    theme: value,
                  }))
                }
                renderInput={params => (
                  <Input
                    {...params}
                    label="Select a theme"
                    variant="outlined"
                    helperText="You can select a theme to show the banner on. If unspecified, it's showing on all themes."
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            onChange={() => setWantsPages(!wantsPages)}
            control={<Checkbox checked={wantsPages} name="pages_check" />}
            label="Do you want to specify the banner to a page or group of pages?"
          />
        </Grid>
        {wantsPages && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="pages"
                options={[]}
                onChange={(_, value) => {
                  setFormData(prevFormData => ({
                    ...prevFormData,
                    pages: value as string[],
                  }));
                }}
                onBlur={event => {
                  const enterKeyEvent = new KeyboardEvent('keydown', {
                    key: 'Enter',
                    code: 'Enter',
                    bubbles: true,
                  });
                  event.target.dispatchEvent(enterKeyEvent);
                }}
                value={(pages as string[]) ?? []}
                multiple
                freeSolo
                renderInput={params => (
                  <Input
                    {...params}
                    label="Select a page or group of pages"
                    variant="outlined"
                  />
                )}
              />
              <FormHelperText>
                You can select a page or group of pages to show the banner on.
                If unspecified, it's showing on all pages. If specified, you can
                use:
                <br />
                <strong>Relative URLs</strong> (e.g.,
                /catalog/default/Product/PRO-50/elastic)
                <br />
                <strong>Absolute URLs</strong> (e.g.,
                http://localhost:3000/catalog/default/Product/PRO-50/elastic),
                <br />
                <strong>Regex patterns</strong> (e.g.,
                ^/catalog/default/Product/PRO-[^/]+/elastic$).
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid>
          <FormControl>
            <FormControlLabel
              onChange={() => setChecked(!checked)}
              control={<Checkbox checked={checked} name="grammar_checked" />}
              label="Have you checked for typos and grammatical errors?"
            />
            <FormHelperText>
              Lets not embarrass ourselves, shall we?
            </FormHelperText>
          </FormControl>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || !checked || !text_body || !title}
        >
          {loading ? `${type}ing banner...` : `${type} banner`}
        </Button>
        {formError && <ResponseErrorPanel error={formError} />}
      </form>
    </>
  );
};
