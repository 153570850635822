import { AmmaError } from './errors/AmmaError';
import {
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { JsonObject } from '@backstage/types';
import {
  ApisEntity,
  CreateConnectionEntity,
} from '@lego/plugin-baseplate-approval-flow';
import {
  IGatewayStatusResponse,
  IGatewayStatusResponseWhenDeploying,
  IGatewayUptime,
} from '../types';
import { IAmmaClient } from './IAmmaClient';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';

export interface RepoBranchesCheckResult {
  username: string;
  repositoryExists: string;
  error: string;
  branches: string[];
}

export interface RepoFilePermissionsCheckResult
  extends RepoBranchesCheckResult {
  permissionToCreatePR: string;
  branchExists: string;
  fileExists: string;
}

export class AmmaClient implements IAmmaClient {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly microsoftAuth: OAuthApi;
  private readonly configApi: ConfigApi;

  constructor({
    fetchApi,
    discoveryApi,
    microsoftAuth,
    configApi,
  }: {
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
    microsoftAuth: OAuthApi;
    configApi: ConfigApi;
  }) {
    this.fetchApi = fetchApi;
    this.configApi = configApi;
    this.discoveryApi = discoveryApi;
    this.microsoftAuth = microsoftAuth;
  }

  async getAmmaStatus(
    environment: AMMA_ENVIRONMENT,
  ): Promise<IGatewayStatusResponse | IGatewayStatusResponseWhenDeploying> {
    const env =
      environment === AMMA_ENVIRONMENT.PRODUCTION ? 'prod' : 'integration';

    const url = new URL(
      `https://${env}.status.api.legogroup.io/v1/status?detailed=true`,
    );

    const scope = this.configApi.getString(
      `integrations.amma.environments.${environment}.scope`,
    );
    const token = await this.microsoftAuth.getAccessToken(scope);

    const response = await this.fetchApi.fetch(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async getAmmaUptime(
    environment: AMMA_ENVIRONMENT,
    start: string,
    end: string,
  ): Promise<IGatewayUptime> {
    const env =
      environment === AMMA_ENVIRONMENT.PRODUCTION ? 'prod' : 'integration';

    const url = new URL(
      `https://${env}.status.api.legogroup.io/v1/uptime?start=${start}&end=${end}`,
    );

    const scope = this.configApi.getString(
      `integrations.amma.environments.${environment}.scope`,
    );
    const token = await this.microsoftAuth.getAccessToken(scope);

    const response = await this.fetchApi.fetch(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  getUrl = async (path: string, environment: string): Promise<URL> => {
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/amma/${environment}${path}`;
    return new URL(url);
  };

  getHeaders = async (environment: string) => {
    const scope = this.configApi.getString(
      `integrations.amma.environments.${environment}.scope`,
    );
    const token = await this.microsoftAuth.getAccessToken(scope);
    const headers = new Headers();
    headers.append('authorization-forward', `Bearer ${token}`);
    return headers;
  };

  async getWorkspaceIdentity(
    workspaceName: string,
    environment: string,
  ): Promise<JsonObject> {
    const url = await this.getUrl(
      `/api/v1/workspaces/${workspaceName}/identity`,
      environment,
    );
    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, { headers });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Retrieve all user's workspaces
   * @returns Array of workspaces that the current user owns.
   */
  async getUsersWorkspaces(environment: string) {
    const url = await this.getUrl('/api/v1/users/me/workspaces', environment);
    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, { headers });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Retrieve all allowed subdomains
   * @returns Array of strings of the allowed subdomains in AMMA.
   */
  async getAllowedSubDomains(environment: string) {
    const url = await this.getUrl(
      '/api/v1/workspaces/allowedsubdomains',
      environment,
    );
    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, { headers });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Create new workspace in AMMA based on user input.
   * @param newWorkspaceName is the name of the workspace user wants to create.
   * @returns Reject the Promise with an object of status 400, the detail(reason of failing),
   * otherwise resolve the Promise with an object of the name of the workspace and the owner(s) in case it was a success
   */
  async createNewWorkspace(newWorkspaceName: string, environment: string) {
    const url = await this.getUrl('/api/v1/workspaces', environment);
    const headers = await this.getHeaders(environment);
    headers.set('Content-Type', 'application/json');
    const response = await this.fetchApi.fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        name: newWorkspaceName,
      }),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Create new client API on a selected workspace in AMMA.
   * @param workspaceName is the name of the workspace on which the user wants to create the client API.
   * @param newClientApiName is the name of the client API user wants to create.
   * @param appId is the app ID.
   * @returns an object containing connections (array),identity, name of the API, scopes(array), specificitations(array)
   */
  async createNewClientAPI(
    workspaceName: string,
    newClientApiName: string,
    appId: string,
    environment: string,
  ) {
    const url = await this.getUrl(
      `/api/v2/workspaces/${workspaceName}/clients`,
      environment,
    );
    const headers = await this.getHeaders(environment);
    headers.set('Content-Type', 'application/json');

    const response = await this.fetchApi.fetch(url, {
      headers,
      method: 'POST',
      body: JSON.stringify({
        name: newClientApiName,
        metadata: { 'edm-application-id': appId },
      }),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Retrieve API details based on Workspace name and API name
   * @returns The details of the requested API - ApisEntity.
   */
  async getApiDetails(
    currentWorkspaceName: string,
    currentApiName: string,
    environment: string,
  ) {
    const url = await this.getUrl(
      `/api/v1/workspaces/${currentWorkspaceName}/apis/${currentApiName}`,
      environment,
    );
    const headers = await this.getHeaders(environment);
    headers.set('Content-Type', 'application/json');

    const response = await this.fetchApi.fetch(url, {
      headers,
      method: 'GET',
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Create API from specification
   * @param workspaceName is the name of the workspace.
   * @param files is the file to create the API from.
   * @returns Promise resolving the response or rejecting with an error.
   */
  async createApiFromSpec(
    workspaceName: string,
    file: File,
    environment: string,
  ) {
    const url = await this.getUrl(
      `/api/v1/workspaces/${workspaceName}/apis`,
      environment,
    );

    const headers = await this.getHeaders(environment);

    const formData = new FormData();
    const arrayBuffer = await file.arrayBuffer();
    const fileStream = new Blob([arrayBuffer]);
    formData.append('file', fileStream, file.name);

    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      body: formData,
      headers,
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Create new federated credentials allowing specified GitHub repo to communicate with AMMA
   * @param accessToken is the access_token generated based on users authentication against OBO-BaseplateClient API.
   * @param clientId is the clientId of the APP registration for which federated credentials were created
   * @param repository is the name of the repository for which federated credentials will be created on AD
   * @param branchName is the name of the branch in repository for which federated credentials will be created on AD
   * @returns an object containing information about successful federated credentials creation/error
   */
  async createFederatedCredentials(
    accessToken: string,
    clientId: string,
    repository: string,
    branchName: string,
  ) {
    const url = `${await this.discoveryApi.getBaseUrl(
      'amma',
    )}/client-federated-credentials`;

    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        accessToken,
        clientId,
        repository,
        branchName,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Create pull request containing workflow handling upload of the specification to AMMA
   * @param githubAccessToken is the user Github access_token allowing Baseplate to open PR in defined repository
   * @param clientId is the clientId of the APP registration for which federated credentials were created
   * @param repository is the name of the repository where the PR will be created
   * @param workspaceName name of the workspace owning the API
   * @param specFilename path of the API specification file in GitHub repository
   * @param branchName is the name of the branch within selected GitHub repository where the PR will be created
   * @returns link to created pull request/error
   */
  async createPullRequest(
    githubAccessToken: string,
    clientId: string,
    repository: string,
    workspaceName: string,
    specFilename: string,
    branchName: string,
    environment: AMMA_ENVIRONMENT,
  ) {
    const url = `${await this.discoveryApi.getBaseUrl('amma')}/register-api-pr`;
    const response: Response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        githubAccessToken,
        clientId,
        repository,
        workspaceName,
        specFilename,
        branchName,
        environment,
      },
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Check if repository in the pasted URL exists on GitHub
   * @param repositoryName is the name of the repository in the paster URL.
   * @param githubAccessToken is the user Github access_token allowing Baseplate to open PR in defined repository.
   * @param branchName is the name of the branch in selected GitHub repository.
   * @param specFilename is the name of the API specification file in selected GitHub repository.
   * @param repositoryOwner is the owner of the repository on GitHub - LEGO in our case.
   * @returns
   */
  async verifyBranchFileAndUserPermissions(
    githubAccessToken: string,
    repositoryName: string,
    branchName: string,
    specFilename: string,
    repositoryOwner: string,
    signal: AbortSignal,
  ) {
    const url = `${await this.discoveryApi.getBaseUrl(
      'amma',
    )}/verify-branch-file-and-user-permissions`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: {
        githubAccessToken,
        repositoryName,
        branchName,
        specFilename,
        repositoryOwner,
      },
      signal,
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Fetch the list of branches for a repository from GitHub
   * @param repositoryName is the name of the repository.
   * @param githubAccessToken is the user Github access_token.
   * @param repositoryOwner is the owner of the repository on GitHub - LEGO in our case.
   * @param signal is the AbortSignal for aborting the fetch request.
   * @returns An array of branch names.
   */
  async fetchBranchesAndVerifyRepository(
    repositoryName: string,
    githubAccessToken: string,
    repositoryOwner: string,
    signal: AbortSignal,
  ) {
    const url = `${await this.discoveryApi.getBaseUrl(
      'amma',
    )}/fetch-branches-and-verify-repository`;
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: {
        githubAccessToken,
        repositoryName,
        repositoryOwner,
      },
      signal,
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async validateApiSpec(files: File, environment: string) {
    const url = await this.getUrl(
      '/api/v1/utilities/validatespecification',
      environment,
    );

    const file = new FormData();
    file.append('file', files, files.name);

    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      body: file,
      headers,
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return Promise.resolve();
  }

  /**
   * Delete API
   * @returns waiting for testing
   */
  async deleteApi(workspaceName: string, apiName: string, environment: string) {
    const url = await this.getUrl(
      `/api/v1/workspaces/${workspaceName}/apis/${apiName}`,
      environment,
    );
    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, {
      method: 'DELETE',
      headers,
    });

    if (!response.ok) {
      return Promise.reject(await response.json());
    }
    return await response.json();
  }

  /**
   * Delete Connection
   * @returns waiting for testing
   */
  async deleteConnection(
    fromWorkspaceName: string,
    fromApiName: string,
    connectToEntity: CreateConnectionEntity,
    environment: string,
  ) {
    const url = await this.getUrl(
      `/api/v1/workspaces/${fromWorkspaceName}/apis/${fromApiName}/connections`,
      environment,
    );

    const headers = await this.getHeaders(environment);
    headers.set('Content-Type', 'application/json');

    const response = await this.fetchApi.fetch(url, {
      headers,
      method: 'DELETE',
      body: JSON.stringify({
        apiName: connectToEntity.apiName,
        workspaceName: connectToEntity.workspaceName,
        scope: connectToEntity.scope,
        reason: connectToEntity.reason,
      }),
    });
    if (!response.ok) {
      throw await AmmaError.fromResponse(response);
    }
    return true;
  }

  /**
   * Retrieve API details based on Workspace name and API name
   * @returns The details of the requested API - ApisEntity.
   */
  async getApiSpecification(
    currentWorkspaceName: string,
    currentApiName: string,
    environment: string,
  ) {
    const url = await this.getUrl(
      `/api/v1/workspaces/${currentWorkspaceName}/apis/${currentApiName}`,
      environment,
    );
    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, { headers });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const apiDetails = (await response.json()) as ApisEntity;
    return apiDetails;
  }

  /**
   * Get Connection Requests
   * status=approved maybe that that in the future in the url
   * @returns The details of connection requests between two APIs
   */
  async getConnections(
    fromWorkspaceName: string,
    fromApiName: string,
    toWorkspaceName: string,
    toApiName: string,
    environment: string,
  ) {
    const url = await this.getUrl('/api/v1/connectionrequests', environment);
    url.searchParams.append('From.Workspace', fromWorkspaceName);
    url.searchParams.append('From.Api', fromApiName);
    url.searchParams.append('To.Workspace', toWorkspaceName);
    url.searchParams.append('To.Api', toApiName);

    const headers = await this.getHeaders(environment);
    const response = await this.fetchApi.fetch(url, { headers });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  /**
   * Creating connection between two APIs - client - consumer connection.
   * @param fromWorkspaceName name of the workspace the user wants to connect from.
   * @param fromApiName name of the API the user wants to connect from.
   * @param connectToEntity object which contains the API name and the workspace name the user wants to connect to and also the scope user wants to request and the reason of the connection (optional).
   * @returns -- waiting for testing
   */
  async createConnection(
    fromWorkspaceName: string,
    fromApiName: string,
    connectToEntity: CreateConnectionEntity,
    environment: string,
  ) {
    const url = await this.getUrl(
      `/api/v1/workspaces/${fromWorkspaceName}/apis/${fromApiName}/connections`,
      environment,
    );

    const headers = await this.getHeaders(environment);
    headers.set('Content-Type', 'application/json');
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        apiName: connectToEntity.apiName,
        workspaceName: connectToEntity.workspaceName,
        scope: connectToEntity.scope,
        reason: connectToEntity.reason,
      }),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await Promise.resolve();
  }
}
