import { stringifyEntityRef } from '@backstage/catalog-model';
import { Content } from '@backstage/core-components';
import { errorApiRef, useAnalytics, useApi } from '@backstage/core-plugin-api';
import {
  ReviewStepProps,
  SecretsContextProvider,
} from '@backstage/plugin-scaffolder-react';
import {
  useFilteredSchemaProperties,
  useTemplateParameterSchema,
} from '@backstage/plugin-scaffolder-react/alpha';
import { JsonValue } from '@backstage/types';
import {
  Spinner,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import React, { useCallback, useEffect } from 'react';

import { Stepper, StepperProps } from './Stepper';
import { useTemplateTimeSavedMinutes } from './hooks/useTemplateTimeSavedMinutes';

const useStyles = baseplateStyles(theme => ({
  markdown: {
    /** to make the styles for React Markdown not leak into the description */
    '& :first-child': {
      marginTop: 0,
    },
    '& :last-child': {
      marginBottom: 0,
    },
  },
  main: {
    backgroundColor: theme.semantic.background.neutral[1],
  },
}));

/**
 * @alpha
 */
export type WorkflowProps = {
  title?: string;
  description?: string;
  namespace: string;
  templateName: string;
  components?: {
    ReviewStepComponent?: React.ComponentType<ReviewStepProps>;
  };
  onError(error: Error | undefined): JSX.Element | null;
  showErrorList?: false | 'top' | 'bottom';
} & Pick<
  StepperProps,
  | 'extensions'
  | 'formProps'
  | 'components'
  | 'onCreate'
  | 'initialState'
  | 'layouts'
>;

/**
 * @alpha
 */
export const Workflow = (workflowProps: WorkflowProps): JSX.Element | null => {
  const { title, description, namespace, templateName, onCreate, ...props } =
    workflowProps;

  const analytics = useAnalytics();
  const styles = useStyles();
  const templateRef = stringifyEntityRef({
    kind: 'Template',
    namespace: namespace,
    name: templateName,
  });

  const minutesSaved = useTemplateTimeSavedMinutes(templateRef);

  const errorApi = useApi(errorApiRef);

  const { loading, manifest, error } = useTemplateParameterSchema(templateRef);

  const sortedManifest = useFilteredSchemaProperties(manifest);

  const workflowOnCreate = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (formState: Record<string, JsonValue>) => {
      void onCreate(formState);
      const name =
        typeof formState.name === 'string' ? formState.name : undefined;
      analytics.captureEvent('create', name ?? templateName ?? 'unknown', {
        value: minutesSaved,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCreate, analytics, templateName],
  );

  useEffect(() => {
    if (error) {
      errorApi.post(new Error(`Failed to load template, ${error.message}`));
    }
  }, [error, errorApi]);

  if (error) {
    return props.onError(error);
  }

  return (
    <Content className={styles.main} noPadding>
      {loading && <Spinner />}
      {sortedManifest && (
        <Stepper
          manifest={sortedManifest}
          onCreate={workflowOnCreate}
          {...props}
        />
      )}
    </Content>
  );
};

/**
 * @alpha
 */
export const EmbeddableWorkflow = (props: WorkflowProps) => (
  <SecretsContextProvider>
    <Workflow {...props} />
  </SecretsContextProvider>
);
