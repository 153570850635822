import { Banner } from '@lego/plugin-baseplate-admin-common';
import {
  Chip,
  EntityLink,
  TableRowType,
} from '@lego/plugin-baseplate-core-components';
import React from 'react';

export const getColumns = (banners: Banner[]): TableRowType<Banner>[] => {
  return banners.map(banner => ({
    id: banner.id,
    data: { ...banner },
    columns: {
      title: {
        type: 'string',
        label: 'Title',
        value: banner.title,
      },
      severity: {
        type: 'string',
        label: 'Severity',
        value: banner.severity,
      },
      text_body: {
        type: 'string',
        label: 'Text Body',
        value: banner.text_body,
      },
      theme: {
        type: 'string',
        label: 'Theme',
        value: banner.theme ?? 'N/A',
      },
      pages: {
        type: 'custom',
        value: banner.pages
          ? banner.pages.map(page => (
              <Chip label={page} title={page} style={{ marginLeft: 4 }} />
            ))
          : 'N/A',
      },
      feature_flag: {
        type: 'string',
        label: 'Feature Flag',
        value: banner.featureFlag ?? 'N/A',
      },
      created_by: {
        type: 'entity',
        label: 'Created By',
        value: banner.created_by,
        icon: (
          <EntityLink entityRef={banner.created_by} variant="inline-link" />
        ),
      },
    },
  }));
};
