import { Content, Page } from '@backstage/core-components';
import { useAnalytics, useRouteRef } from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { useTaskEventStream } from '@backstage/plugin-scaffolder-react';
import { FormStepTitle } from '@lego/plugin-baseplate-core-components';

import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TemplateOutputs, CustomScaffolderTaskOutput } from './TemplateOutputs';
import { TemplateStep } from './TemplateStep';

export const OngoingTask = () => {
  const { taskId } = useParams();
  const templateRouteRef = useRouteRef(
    scaffolderPlugin.routes.selectedTemplate,
  );
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const taskStream = useTaskEventStream(taskId!);

  const steps = useMemo(
    () =>
      taskStream.task?.spec.steps.map(step => ({
        ...step,
        ...taskStream?.steps?.[step.id],
      })) ?? [],
    [taskStream],
  );

  const { namespace, name, title } =
    taskStream.task?.spec.templateInfo?.entity?.metadata ?? {};

  const startOver = useCallback(
    (url?: string) => {
      if (url) {
        const urlSplit = url.split('/');

        urlSplit.forEach((item, idx) => {
          if (item.includes('parameters')) {
            const parametersKey = item.split('.')[1];
            urlSplit[idx] = String(
              taskStream?.task?.spec?.parameters[parametersKey] ?? '',
            );
          }
        });
        const newUrl = urlSplit.join('/');
        analytics.captureEvent(
          'click',
          `Task has been navigate to the URL : ${newUrl}`,
        );

        navigate(newUrl);
      } else {
        const formData = taskStream.task?.spec.parameters ?? {};

        if (!namespace || !name) {
          return;
        }

        analytics.captureEvent('click', `Task has been started over`);

        navigate({
          pathname: templateRouteRef({
            namespace,
            templateName: name,
          }),
          search: `?${qs.stringify({ formData: JSON.stringify(formData) })}`,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      analytics,
      navigate,
      taskStream.task?.spec.parameters,
      taskStream.task?.spec.templateInfo?.entity?.metadata,
      templateRouteRef,
    ],
  );

  const cancelEnabled = !(taskStream.cancelled || taskStream.completed);

  const getStepTitle = () => {
    if (taskStream.error) {
      return 'Failed';
    }
    if (taskStream.completed) {
      return 'Completed';
    }
    return 'Processing';
  };

  return (
    <Page themeId="website">
      <Content>
        <div className="narrow-layout">
          <FormStepTitle
            formTitle={title ?? 'Template'}
            stepTitle={getStepTitle()}
          />

          {steps.map(step => (
            <TemplateStep step={step} logs={taskStream.stepLogs[step.id]} />
          ))}
          <TemplateOutputs
            output={taskStream.output as CustomScaffolderTaskOutput}
            completed={taskStream.completed}
            error={taskStream.error}
            taskId={taskId}
            steps={steps}
            startOver={startOver}
            cancelEnabled={cancelEnabled}
            metadata={taskStream.task?.spec.templateInfo?.entity?.metadata}
          />
        </div>
      </Content>
    </Page>
  );
};
