import { Banner } from '@lego/plugin-baseplate-admin-common';
import { SidePanel } from '@lego/plugin-baseplate-core-components';
import React, { useState } from 'react';
import { CreateBanner, EditBanner } from './side-panel-forms';
import { BannerTable } from './table';

export const BannerPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [banner, setBanner] = useState<Banner>();
  const [sidePanelType, setSidePanelType] = useState<'create' | 'edit'>(
    'create',
  );

  const handleOpenEdit = (chosenBanner: Banner) => {
    setBanner(chosenBanner);
    setSidePanelType('edit');
    setIsOpen(true);
  };

  const handleOpenCreate = () => {
    setSidePanelType('create');
    setIsOpen(true);
  };

  const isCreate = sidePanelType === 'create' || !banner;

  return (
    <>
      <BannerTable
        handleOpenEdit={handleOpenEdit}
        handleOpenCreate={handleOpenCreate}
      />
      <SidePanel
        title={sidePanelType === 'create' ? 'Create Banner' : 'Edit Banner'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        {isCreate ? (
          <CreateBanner setIsOpen={setIsOpen} />
        ) : (
          <EditBanner banner={banner} setIsOpen={setIsOpen} />
        )}
      </SidePanel>
    </>
  );
};
