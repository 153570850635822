import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  AnalyticsContext,
  useApi,
  useRouteRef,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  FieldExtensionOptions,
  FormProps,
  ReviewStepProps,
  scaffolderApiRef,
  useTemplateSecrets,
  type LayoutOptions,
} from '@backstage/plugin-scaffolder-react';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { Page } from '@backstage/core-components';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { JsonValue } from '@backstage/types';
import { Workflow } from './Workflow';

/**
 * @alpha
 */
export type TemplateWizardPageProps = {
  customFieldExtensions: FieldExtensionOptions<any, any>[];
  components?: {
    ReviewStepComponent?: React.ComponentType<ReviewStepProps>;
  };
  layouts?: LayoutOptions[];
  formProps?: FormProps;
  headerOptions?: {
    pageTitleOverride?: string;
    title?: string;
    subtitle?: string;
  };
  showErrorList?: false | 'top' | 'bottom';
};
export const TemplateWizardPage = (props: TemplateWizardPageProps) => {
  const rootRef = useRouteRef(scaffolderPlugin.routes.root);
  const taskRoute = useRouteRef(scaffolderPlugin.routes.ongoingTask);
  const { secrets } = useTemplateSecrets();
  const scaffolderApi = useApi(scaffolderApiRef);
  const navigate = useNavigate();
  const { templateName, namespace } = useRouteRefParams(
    scaffolderPlugin.routes.selectedTemplate,
  );

  const templateRef = stringifyEntityRef({
    kind: 'Template',
    namespace,
    name: templateName,
  });

  const onCreate = async (values: Record<string, JsonValue>) => {
    const { taskId } = await scaffolderApi.scaffold({
      templateRef,
      values,
      secrets,
    });

    navigate(taskRoute({ taskId }));
  };

  const onError = () => <Navigate to={rootRef()} />;

  return (
    <AnalyticsContext attributes={{ entityRef: templateRef }}>
      <Page themeId="website">
        <Workflow
          namespace={namespace}
          templateName={templateName}
          onCreate={onCreate}
          components={props.components}
          onError={onError}
          extensions={props.customFieldExtensions}
          formProps={props.formProps}
          layouts={props.layouts}
          showErrorList={props.showErrorList}
        />
      </Page>
    </AnalyticsContext>
  );
};
