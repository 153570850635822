import { getCloudAccountName } from './getCloudAccountName';
import {
  mapValuesToAWSPayload,
  mapValuesToAzurePayload,
} from './formatValuesForApi';
import { useCloudMutation } from '../../common/api/useCloudApi';
import { FormValues } from './types';

export const useCreateCloudAccount = (values: FormValues) => {
  const cloudAccountName = getCloudAccountName(
    values.name.toString(),
    values.environment.toString(),
  );
  const intermediateValues = { ...values, name: cloudAccountName };

  const finalSubmitValues =
    values.vendor === 'Azure'
      ? mapValuesToAzurePayload(intermediateValues)
      : mapValuesToAWSPayload(intermediateValues);

  const path = values.vendor === 'Azure' ? '/subscriptions' : `/aws-account`;

  return useCloudMutation(path, {
    method: 'POST',
    body: JSON.stringify(finalSubmitValues),
  });
};
