import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityOwnerFilter,
  entityPresentationApiRef,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import React, { useEffect, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { AutocompleteDropdown } from '../autocomplete-dropdown';

type OwnerOption = {
  label: string;
  entityRef: string;
};

export const BaseplateOwnerPicker = () => {
  const {
    updateFilters,
    filters,
    queryParameters: { owners: ownersParameter },
  } = useEntityList();
  const catalogApi = useApi(catalogApiRef);
  const entityPresentationApi = useApi(entityPresentationApiRef);

  const queryParamOwners = useMemo(
    () => [ownersParameter].flat().filter(Boolean) as string[],
    [ownersParameter],
  );

  const [selectedOwners, setSelectedOwners] = useState(
    queryParamOwners.length ? queryParamOwners : filters.owners?.values ?? [],
  );

  const { loading, value: ownerEntities } = useAsync(async () => {
    const { facets } = await catalogApi.getEntityFacets({
      facets: ['spec.owner'],
      filter: { kind: ['API'] },
    });

    const owners = await Promise.all(
      facets['spec.owner'].map(async facet => {
        const { promise } = entityPresentationApi.forEntity(facet.value);
        const { primaryTitle } = await promise;
        return {
          label: primaryTitle,
          entityRef: facet.value,
        };
      }),
    );

    return owners.sort((a, b) =>
      a.label.localeCompare(b.label, 'en-US', {
        ignorePunctuation: true,
        caseFirst: 'upper',
      }),
    );
  }, [catalogApi, entityPresentationApi]);

  useEffect(() => {
    if (queryParamOwners.length) {
      setSelectedOwners(queryParamOwners);
    }
  }, [queryParamOwners]);

  useEffect(() => {
    if (!loading && ownerEntities) {
      updateFilters({
        owners:
          selectedOwners.length !== 0 && ownerEntities.length !== 0
            ? new EntityOwnerFilter(
                selectedOwners.map(s => s.toLocaleLowerCase()),
              )
            : undefined,
      });
    }
  }, [selectedOwners, updateFilters, ownerEntities, loading]);

  return (
    <AutocompleteDropdown
      multiple
      required
      label="Owner"
      loading={loading}
      options={ownerEntities || []}
      value={
        loading || !ownerEntities
          ? []
          : ownerEntities.filter((e: OwnerOption) =>
              selectedOwners.some((f: string) => f === e.entityRef),
            ) ?? []
      }
      onChange={(_: object, value: Array<OwnerOption>) =>
        setSelectedOwners(value.map(e => e.entityRef))
      }
      getOptionLabel={(option: OwnerOption) => option.label}
      size="small"
    />
  );
};
