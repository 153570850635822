import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Button, Card } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import { Add } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useEntityEventsStyles } from './EntityEvents.styles';
import useAsync from 'react-use/lib/useAsync';
import { eventsApiRef } from '../../api';
import { EventsPreview } from '../Events/EventsPreview';
import {
  defaultDateRange,
  formatDate,
} from '../Events/CalendarFilter/Calendar.config';

export const EntityEvents = () => {
  const classes = useEntityEventsStyles();
  const { entity } = useEntity();
  const eventsAPI = useApi(eventsApiRef);
  const navigate = useNavigate();

  const { loading, value: events } = useAsync(async () => {
    const discoverEventsParams = new URLSearchParams({
      startDateTime: formatDate(defaultDateRange().from, 'start'),
      endDateTime: formatDate(defaultDateRange().to, 'end'),
    });
    discoverEventsParams.append(
      entity.kind.toLocaleLowerCase(),
      `${entity.metadata.namespace ?? 'default'}/${entity.metadata.name}`,
    );

    const { data } = await eventsAPI.getEvents(discoverEventsParams.toString());
    return data ?? [];
  });

  const goToEvent = useCallback(
    (eventId: string) => {
      navigate(`/communities/events/${eventId}`);
    },
    [navigate],
  );

  const goToEvents = useCallback(
    (newEvent: boolean) => {
      const entityRef = `${entity.metadata.namespace}/${entity.metadata.name}`;
      const url = newEvent
        ? `manage-events/new?context=${entity.kind.toLowerCase()}&entityRef=${entityRef.toLowerCase()}`
        : `events`;
      navigate(`/communities/${url}`);
    },
    [navigate, entity],
  );
  return (
    <Card title="Upcoming Events">
      <Button variant="text" onClick={() => goToEvents(false)}>
        View all events
      </Button>
      {events && events.length > 0 ? (
        <EventsPreview
          events={events.slice(0, 3)}
          onEventClick={goToEvent}
          isLoading={loading}
        />
      ) : (
        <div className={classes.noEventGrp}>
          <Typography variant="body1">
            There are no events connected to this {entity.kind}
          </Typography>
          <Typography variant="body1">
            Events connected to your {entity.kind} will appear here.
          </Typography>
          <Button
            variant="primary"
            startIcon={<Add />}
            onClick={() => goToEvents(true)}
          >
            Host an Event
          </Button>
          <Typography variant="body1">
            Empower your {entity.kind} by hosting your next event!
          </Typography>
        </div>
      )}
    </Card>
  );
};
