import React from 'react';
import { EntityApiDefinitionCard } from '@backstage/plugin-api-docs';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Grid } from '@material-ui/core';
import { entityWarningContent } from '../ContentSections';
import { BaseplateAboutCard } from '../CustomComponents/BaseplateAboutCard';
import { EntityLinksCard as ApiEntityLinksCard } from '../EntityLinksCard';
import { LinterPage } from '@lego/plugin-baseplate-api-linter';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import {
  ApiDeploymentLogsPage,
  ApiMetricsPage,
} from '@lego/plugin-baseplate-api-metrics';
import { RELATION_API_CONSUMED_BY } from '@backstage/catalog-model';
import { useExternalLink } from '../hooks';
import { RenavigateContent } from './ExternalLinkUtils';
import { RouteTitleWithIcon } from './ExternalLinkUtils/RouteTitleWithIcon';
import { EspMetricsPage } from '@lego/backstage-plugin-baseplate-esp-status';
import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { Button } from '@lego/plugin-baseplate-core-components';

export const OldApiDetailPage = () => {
  const { entity } = useEntity();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const isNewDetailPage = featureFlagsApi.isActive('api-detail');
  const { externalLink } = useExternalLink(entity);

  const workspaceToConnect =
    entity?.metadata?.annotations !== undefined
      ? entity?.metadata?.annotations['baseplate.legogroup.io/raw-namespace']
      : undefined;

  const apiToConnect =
    entity?.metadata?.annotations !== undefined
      ? entity?.metadata?.annotations['baseplate.legogroup.io/raw-name']
      : undefined;

  const productId = entity.spec?.owner as string;

  const environment = entity.spec?.environment as string;

  const workspace = entity.metadata.annotations && {
    label: entity.metadata.annotations['baseplate.legogroup.io/raw-namespace'],
    href: `/catalog/default/workspace/${entity.metadata.annotations['baseplate.legogroup.io/raw-namespace']}`,
  };

  const switchToNewVersion = () => {
    featureFlagsApi.save({ states: { 'api-detail': 1 }, merge: true });
    location.reload();
  };

  return (
    <EntityLayout
      breadcrumbLinks={[
        { label: 'API', href: '/api-docs' },
        ...(workspace ? [workspace] : []),
      ]}
      actions={[
        <Button variant="secondary" onClick={switchToNewVersion}>
          Switch to {isNewDetailPage ? 'old' : 'new'} version{' '}
          {isNewDetailPage ? '' : '(BETA)'}{' '}
        </Button>,
        <Button
          variant="primary"
          href={`/request-access/workspace/${workspaceToConnect}/api/${apiToConnect}/environment/${environment}${
            productId ? `/owner/${productId}` : ''
          }`}
        >
          Request access
        </Button>,
      ]}
      labels={e => {
        const isProduction = e.spec?.environment === 'production';
        return [
          {
            title: entity.spec?.environment as string,
            color: isProduction ? 'green' : 'yellow',
          },
          {
            title: entity.spec?.type as string,
            color: 'success',
          },
        ];
      }}
    >
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={4}>
          {entityWarningContent}
          <Grid item md={6} xs={12}>
            <BaseplateAboutCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <ApiEntityLinksCard variant="gridItem" />
          </Grid>
          <Grid item xs={12}>
            <EntityCatalogGraphCard
              variant="gridItem"
              height={300}
              title="API connections"
              relations={[RELATION_API_CONSUMED_BY]}
            />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/definition" title="Definition">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EntityApiDefinitionCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {featureFlagsApi.isActive('api-linter') &&
        entity.spec?.type === 'openapi' &&
        entity.spec?.apiScore !== undefined &&
        entity.spec?.apiScore !== 0 && (
          <EntityLayout.Route path="/api-linter" title="Linter">
            <LinterPage />
          </EntityLayout.Route>
        )}
      {(entity.spec?.type === 'openapi' || entity.spec?.type === 'graphql') && (
        <EntityLayout.Route path="/api-metrics" title="Metrics">
          <ApiMetricsPage />
        </EntityLayout.Route>
      )}
      {entity.spec?.type === 'asyncapi' && (
        <EntityLayout.Route path="/esp-metrics" title="Metrics">
          <EspMetricsPage />
        </EntityLayout.Route>
      )}
      {(entity.spec?.type === 'openapi' || entity.spec?.type === 'graphql') && (
        <EntityLayout.Route path="/deployments" title="Deployments">
          <ApiDeploymentLogsPage />
        </EntityLayout.Route>
      )}
      {!!externalLink && (
        <EntityLayout.Route
          path={externalLink}
          title={(<RouteTitleWithIcon title="Docs" />) as unknown as string}
        >
          <RenavigateContent />
        </EntityLayout.Route>
      )}
    </EntityLayout>
  );
};
