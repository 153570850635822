/* tslint:disable */
/* eslint-disable */
/**
 * AWSLandingZone
 * AWS Cloud Landing Zone Account Factory and Management REST APIs.                                         This API specification also applies to our AWS API Gateway China partition API.                                         The only difference is that China does not use Cognito for Authorization.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: 12e6b798.o365.corp.LEGO.com@emea.teams.ms
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MSTeamsChannelEmailEndpointProperties } from './MSTeamsChannelEmailEndpointProperties';
import {
  MSTeamsChannelEmailEndpointPropertiesFromJSON,
  MSTeamsChannelEmailEndpointPropertiesFromJSONTyped,
  MSTeamsChannelEmailEndpointPropertiesToJSON,
  MSTeamsChannelEmailEndpointPropertiesToJSONTyped,
} from './MSTeamsChannelEmailEndpointProperties';
import type { MSTeamsChannelEmail } from './MSTeamsChannelEmail';
import {
  MSTeamsChannelEmailFromJSON,
  MSTeamsChannelEmailFromJSONTyped,
  MSTeamsChannelEmailToJSON,
  MSTeamsChannelEmailToJSONTyped,
} from './MSTeamsChannelEmail';

/**
 *
 * @export
 * @interface AwsAccountCreationRequestBodyNotificationEndpointsInner
 */
export interface AwsAccountCreationRequestBodyNotificationEndpointsInner {
  /**
   * The type of the endpoint. Must be of one of the allowed values
   * @type {string}
   * @memberof AwsAccountCreationRequestBodyNotificationEndpointsInner
   */
  endpointType: AwsAccountCreationRequestBodyNotificationEndpointsInnerEndpointTypeEnum;
  /**
   *
   * @type {MSTeamsChannelEmailEndpointProperties}
   * @memberof AwsAccountCreationRequestBodyNotificationEndpointsInner
   */
  endpointProperties: MSTeamsChannelEmailEndpointProperties;
}

/**
 * @export
 */
export const AwsAccountCreationRequestBodyNotificationEndpointsInnerEndpointTypeEnum =
  {
    MSTeamsChannelEmail: 'MSTeamsChannelEmail',
  } as const;
export type AwsAccountCreationRequestBodyNotificationEndpointsInnerEndpointTypeEnum =
  (typeof AwsAccountCreationRequestBodyNotificationEndpointsInnerEndpointTypeEnum)[keyof typeof AwsAccountCreationRequestBodyNotificationEndpointsInnerEndpointTypeEnum];

/**
 * Check if a given object implements the AwsAccountCreationRequestBodyNotificationEndpointsInner interface.
 */
export function instanceOfAwsAccountCreationRequestBodyNotificationEndpointsInner(
  value: object,
): value is AwsAccountCreationRequestBodyNotificationEndpointsInner {
  if (!('endpointType' in value) || value['endpointType'] === undefined)
    return false;
  if (
    !('endpointProperties' in value) ||
    value['endpointProperties'] === undefined
  )
    return false;
  return true;
}

export function AwsAccountCreationRequestBodyNotificationEndpointsInnerFromJSON(
  json: any,
): AwsAccountCreationRequestBodyNotificationEndpointsInner {
  return AwsAccountCreationRequestBodyNotificationEndpointsInnerFromJSONTyped(
    json,
    false,
  );
}

export function AwsAccountCreationRequestBodyNotificationEndpointsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AwsAccountCreationRequestBodyNotificationEndpointsInner {
  if (json == null) {
    return json;
  }
  return {
    endpointType: json['endpointType'],
    endpointProperties: MSTeamsChannelEmailEndpointPropertiesFromJSON(
      json['endpointProperties'],
    ),
  };
}

export function AwsAccountCreationRequestBodyNotificationEndpointsInnerToJSON(
  json: any,
): AwsAccountCreationRequestBodyNotificationEndpointsInner {
  return AwsAccountCreationRequestBodyNotificationEndpointsInnerToJSONTyped(
    json,
    false,
  );
}

export function AwsAccountCreationRequestBodyNotificationEndpointsInnerToJSONTyped(
  value?: AwsAccountCreationRequestBodyNotificationEndpointsInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    endpointType: value['endpointType'],
    endpointProperties: MSTeamsChannelEmailEndpointPropertiesToJSON(
      value['endpointProperties'],
    ),
  };
}
