import { createApiRef } from '@backstage/core-plugin-api';
import { JsonObject } from '@backstage/types';
import {
  LEGO_ApiManagement_ManagementApi_Contracts_Output_ApiResponse,
  LEGO_ApiManagement_ManagementApi_Contracts_Output_WorkspaceResponse,
} from '@lego/plugin-baseplate-amma-client';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';
import {
  ApisEntity,
  CreateConnectionEntity,
} from '@lego/plugin-baseplate-approval-flow';
import { PullRequestResponse } from '@lego/plugin-baseplate-register-api';
import {
  IGatewayStatusResponse,
  IGatewayStatusResponseWhenDeploying,
  IGatewayUptime,
} from '../types';
import {
  RepoBranchesCheckResult,
  RepoFilePermissionsCheckResult,
} from './AmmaClient';

export const ammaApiRef = createApiRef<IAmmaClient>({
  id: 'plugin.amma.service',
});

export interface IAmmaClient {
  getAmmaUptime(
    environment: string,
    start: string,
    end: string,
  ): Promise<IGatewayUptime>;
  getAmmaStatus(
    environment: string,
  ): Promise<IGatewayStatusResponse | IGatewayStatusResponseWhenDeploying>;
  getWorkspaceIdentity(
    workspaceName: string,
    environment: string,
  ): Promise<JsonObject>;
  getUsersWorkspaces(
    environment: string,
  ): Promise<
    LEGO_ApiManagement_ManagementApi_Contracts_Output_WorkspaceResponse[]
  >;
  getAllowedSubDomains(environment: string): Promise<string[]>;
  createNewWorkspace(
    newWorkspaceName: string,
    environment: string,
  ): Promise<JsonObject>;
  createNewClientAPI(
    workspaceName: string,
    newClientApiName: string,
    appId: string,
    environment: string,
  ): Promise<JsonObject>;
  getApiDetails(
    currentWorkspaceName: string,
    currentApiName: string,
    environment: string,
  ): Promise<LEGO_ApiManagement_ManagementApi_Contracts_Output_ApiResponse>;
  createApiFromSpec(
    workspaceName: string,
    file: File,
    environment: string,
  ): Promise<JsonObject>;
  createFederatedCredentials(
    accessToken: string,
    clientId: string,
    repository: string,
    branchName: string,
  ): Promise<JsonObject>;
  createPullRequest(
    githubAccessToken: string,
    clientId: string,
    repository: string,
    workspaceName: string,
    specFilename: string,
    branchName: string,
    environment: AMMA_ENVIRONMENT,
  ): Promise<PullRequestResponse | boolean>;
  verifyBranchFileAndUserPermissions(
    githubAccessToken: string,
    repositoryName: string,
    branchName: string,
    specFilename: string,
    repositoryOwner: string,
    signal: AbortSignal,
  ): Promise<RepoFilePermissionsCheckResult>;
  fetchBranchesAndVerifyRepository(
    repositoryName: string,
    githubAccessToken: string,
    repositoryOwner: string,
    signal: AbortSignal,
  ): Promise<RepoBranchesCheckResult>;
  validateApiSpec(files: File, environment: string): Promise<void>;
  deleteApi(
    workspaceName: string,
    apiName: string,
    environment: string,
  ): Promise<JsonObject>;
  deleteConnection(
    fromWorkspaceName: string,
    fromApiName: string,
    connectToEntity: CreateConnectionEntity,
    environment: string,
  ): Promise<boolean>;
  getApiSpecification(
    currentWorkspaceName: string,
    currentApiName: string,
    environment: string,
  ): Promise<ApisEntity>;
  getConnections(
    fromWorkspaceName: string,
    fromApiName: string,
    toWorkspaceName: string,
    toApiName: string,
    environment: string,
  ): Promise<ApisEntity>;
  createConnection(
    fromWorkspaceName: string,
    fromApiName: string,
    connectToEntity: CreateConnectionEntity,
    environment: string,
  ): Promise<void>;
}
