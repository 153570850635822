import React from 'react';
import {
  FormStepTitle,
  SelectableCard,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../utils/types';
import { Link } from '@backstage/core-components';
import { ErrorMessage } from 'formik';
import { Alert } from '@mui/material';
import AwsIcon from '../../../../cloud/src/common/util/AwsIcon';
import { SmallAzureIcon } from '../../../../cloud/src/common/util/SmallAzureIcon';

const defaultMonthlyBudget = (vendor: string) => {
  switch (vendor) {
    case 'Azure':
      return 700; // DKK
    case 'AWS':
      return 100; // USD
    case 'AWS China':
      return 700; // CNY
    default:
      return 100;
  }
};

export const StepChooseVendor = ({
  values,
  updateValue,
  productId,
}: StepProps) => {
  return (
    <Grid>
      <FormStepTitle
        formTitle={`Create Cloud Account for ${productId}`}
        stepTitle="Choose vendor"
        step={1}
      />
      <Box mt={1} mb={1}>
        <Typography variant="body1">
          Consult the <Link to="/docs">cloud vendor guide</Link> to determine
          which provider best suits your us case.
        </Typography>
      </Box>
      <SelectableCard
        name="type"
        value={values?.vendor as string}
        onChange={vendor => {
          updateValue('vendor', vendor);
          updateValue('monthlyBudget', defaultMonthlyBudget(vendor));
        }}
        options={[
          {
            label: 'Azure',
            description:
              'Microsoft Azure is a cloud platform offering over 200 services, including AI, machine learning, and IoT, with a global presence.',
            value: 'Azure',
            icon: (
              <Box
                sx={{
                  bgcolor: 'white',
                  padding: 3,
                  width: 32,
                  height: 32,
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SmallAzureIcon />
              </Box>
            ),
          },
          {
            label: 'AWS',
            description:
              'Amazon Web Services offers a comprehensive suite of cloud computing services globally.',
            value: 'AWS',
            icon: (
              <Box
                sx={{
                  bgcolor: 'white',
                  padding: 3,
                  width: 32,
                  height: 32,
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AwsIcon />
              </Box>
            ),
          },
          {
            label: 'AWS China',
            description:
              'Amazon Web Services China provides localized cloud computing solutions within China, operated by local providers.',
            value: 'AWS China',
            icon: (
              <Box
                sx={{
                  bgcolor: 'white',
                  padding: 3,
                  width: 32,
                  height: 32,
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <AwsIcon />
              </Box>
            ),
          },
        ]}
      ></SelectableCard>
      <ErrorMessage name="vendor">
        {msg => (
          <Box mt={2}>
            <Alert severity="error">{msg}</Alert>
          </Box>
        )}
      </ErrorMessage>
    </Grid>
  );
};
