import { TableHeaderType } from '@lego/plugin-baseplate-core-components';

export const headers: TableHeaderType[] = [
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Severity',
    key: 'severity',
  },
  {
    label: 'Text Body',
    key: 'text_body',
  },
  {
    label: 'Theme',
    key: 'theme',
  },
  {
    label: 'Pages',
    key: 'pages',
  },
  {
    label: 'Feature flag',
    key: 'feature_flag',
  },
  {
    label: 'Created By',
    key: 'created_by',
  },
];
