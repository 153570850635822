import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  IdentityProviders,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { microsoftAuthApiRef, useAnalytics } from '@backstage/core-plugin-api';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import { default as React } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apis } from './apis';
import { Root } from './components/Root';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/page/SearchPage';
import { UserSettingsPage } from './components/settings';

import { ScaffolderPage } from '@backstage/plugin-scaffolder';
import { ApprovalFlowPage } from '@lego/plugin-baseplate-approval-flow';
import { RegisterApiPage } from '@lego/plugin-baseplate-register-api';

import {
  CloudAdminPage,
  CloudPage,
  CloudForm,
  cloudCreateFormUrlPattern,
} from '@lego/plugin-baseplate-cloud';

import { DevToolsPage } from '@backstage/plugin-devtools';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { RequirePermission } from '@backstage/plugin-permission-react';
import {
  LightBox,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  EspStatusPage,
  EspMetricsPage,
} from '@lego/backstage-plugin-baseplate-esp-status';
import { AdminPage, Banners } from '@lego/plugin-baseplate-admin';
import { adminPageReadPermission } from '@lego/plugin-baseplate-admin-common';
import { DocsAnalyticsPage } from '@lego/plugin-baseplate-analytics';
import { LinterPage } from '@lego/plugin-baseplate-api-linter';
import {
  ApiDeploymentLogsPage,
  ApiMetricsPage,
} from '@lego/plugin-baseplate-api-metrics';
import { ApiExplorerPage } from '@lego/plugin-baseplate-api-overview';
import { AsmaPage } from '@lego/plugin-baseplate-asma';
import { CommunitiesPage } from '@lego/plugin-baseplate-communities';
import {
  EntityLink,
  SupportProvider,
  UserContextProvider,
  FeatureEnabledForEntityProvider,
} from '@lego/plugin-baseplate-core-components';
import { ElasticPage } from '@lego/plugin-baseplate-elastic';
import { FossIdPage } from '@lego/plugin-baseplate-fossid';
import {
  GitHubConfigContextProvider,
  GithubCopilotPopupCard,
  GithubPage,
} from '@lego/plugin-baseplate-github';
import {
  HostingPlatform,
  ServiceForm,
  SupportLinkFieldExtension,
  HPServiceTypeInstance,
} from '@lego/plugin-baseplate-hosting-platform';
import { IdentityPage } from '@lego/plugin-baseplate-identity';
import { JiraPage } from '@lego/plugin-baseplate-jira';
import { LearnDevPage } from '@lego/plugin-baseplate-learning-and-development';
import { MacBuildCloudPage } from '@lego/plugin-baseplate-mac-build-cloud';
import { NasuniFormPage } from '@lego/plugin-baseplate-nasuni-form';
import { NexusDatabricksStorageCredentialsExternalLocationsPage } from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations';
import { NovusPage as NovusPageLegacy } from '@lego/plugin-baseplate-novus';
import { PagerDutyPageContainer } from '@lego/plugin-baseplate-pager-duty-form';
import { ParticlesPage } from '@lego/plugin-baseplate-particles';
import { PeopleToProductPage } from '@lego/plugin-baseplate-people-to-product';
import { peopleLeaderAccess } from '@lego/plugin-baseplate-people-to-product-common';
import { PluginAppConfigPage } from '@lego/plugin-baseplate-plugin-app-config';
import { LangChainPage } from '@lego/plugin-baseplate-ai';
import {
  CalloutFieldExtension,
  DateExtension,
  EntityPickerFieldExtension,
  EntityTagsPickerExtension,
  GithubTokenFieldExtension,
  ImageUploadExtension,
  LinksAndActionsExtension,
  MarkdownContentExtension,
  MultiStringFieldExtension,
  OngoingTask,
  SelectableRadioCardExtension,
  TemplateWizardPage,
  ToolCategoryPickerExtension,
  ProductRelationPickerFieldExtension,
  CustomSupportLinkExtension,
  AzureTokenFieldExtension,
  AutoCompleteFieldExtension,
  CurrentEntityFieldExtension,
  DependencyDisplayFieldExtension,
} from '@lego/plugin-baseplate-scaffolder-react';
import { SnykPage } from '@lego/plugin-baseplate-snyk';
import { BaseplateTemplatesPage } from '@lego/plugin-baseplate-templates';
import {
  CreationOpenPage,
  DecommissionPage,
} from '@lego/plugin-baseplate-vm-management-form';
import { VmSnapshotPage } from '@lego/plugin-baseplate-vm-snapshot';
import { VulnerabilityGamificationCard } from '@lego/plugin-baseplate-vulnerability-gamification';
import { ZabbixPage } from '@lego/plugin-baseplate-zabbix-management';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { SelectFieldFromApiExtension } from '@roadiehq/plugin-scaffolder-frontend-module-http-request-field';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFoundErrorPage from './components/NotFoundErrorPage';
import { RedirectMyProduct } from './components/catalog/RedirectMyProduct/RedirectMyProduct';
import { RedirectMyProfile } from './components/catalog/RedirectMyProfile/RedirectMyProfile';
import { ProductsExplorerPage } from './components/catalog/products';
import { ToolsExplorerPage } from './components/catalog/tools';
import { HomePage } from './components/home/HomePage';
import { SearchModalContextProvider } from './components/search';
import { systemIcons } from './components/systemIcons';
import {
  Codeblock,
  ColorLinks,
  ContentComponents,
  GlobalStyles,
  MagicLinks,
  TableOfContentActions,
  DocumentationPage,
} from './components/documentation';
import { techDocsPage } from './components/documentation/TechDocsPage';
import { FiveStarFeedbackToastifyPathTrigger } from './feedback/FiveStarFeedbackToastifyPathTrigger';
import { FiveStarFeedbackToastifyUseBPTrigger } from './feedback/FiveStarFeedbackToastifyUseBPTrigger';
import * as plugins from './plugins';
import { getTheme } from './style/colorSchemes';
import { NexusIngestionPage } from '@lego/plugin-baseplate-nexus-ingestion';
import { MssqlPage } from '@lego/plugin-baseplate-hosting-platform-mssql';
import {
  AdminAccountExtension,
  ServersDropdownExtension,
} from '@lego/backstage-plugin-server-access-form';
import { RabbitmqPage } from '@lego/plugin-baseplate-hosting-platform-rabbitmq';
import { NovusPage } from '@lego/plugin-baseplate-hosting-platform-novus';

const providers: IdentityProviders = [
  {
    id: 'microsoft-auth-provider',
    title: 'Microsoft',
    message: 'Sign in using Microsoft',
    apiRef: microsoftAuthApiRef,
  },
];

if (process.env.NODE_ENV === 'development') {
  providers.push('guest');
}

const queryClient = new QueryClient();

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  featureFlags: [
    {
      name: 'the-homepage',
      description: 'Enables the new baseplate homepage',
      pluginId: '',
    },
    {
      pluginId: '',
      name: 'hosting-platform',
      description: 'Enables the edge tab on product page.',
    },
    {
      pluginId: 'plugin-baseplate-api-detail',
      name: 'api-detail',
      description: 'The new API detail page',
    },
  ],
  themes: [
    {
      id: 'light-theme',
      title: 'light theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={getTheme('light')}>
          <ToastContainer
            theme="light"
            enableMultiContainer
            containerId="in-front-of-side-panel"
            pauseOnFocusLoss
            draggable
            closeOnClick
          />
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'dark-theme',
      title: 'dark theme',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={getTheme('dark')}>
          <CssBaseline>
            <ToastContainer
              theme="dark"
              enableMultiContainer
              containerId="in-front-of-side-panel"
              pauseOnFocusLoss
              draggable
              closeOnClick
            />
            {children}
          </CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  icons: systemIcons,
  components: {
    SignInPage: props => {
      const analytics = useAnalytics();
      return (
        <SignInPage
          {...props}
          providers={providers}
          title="Select a sign-in method"
          align="center"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSignInSuccess={async identityApi => {
            const profileInfo = await identityApi.getProfileInfo();
            const { displayName, email } = profileInfo;
            analytics.captureEvent('login', window.location.pathname, {
              attributes: {
                displayName: displayName as string,
                email: email as string,
              },
            });
            // Forward results
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
    NotFoundErrorPage: () => {
      return <NotFoundErrorPage />;
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/my-profile" element={<RedirectMyProfile />} />
    <Route path="/my-product" element={<RedirectMyProduct />} />
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/create-documentation" element={<BaseplateTemplatesPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name/"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/identity" element={<IdentityPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      {techDocsPage}
      <TechDocsAddons>
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
        <ReportIssue />
        <ColorLinks />
        <MagicLinks />
        <LightBox />
        <Codeblock />
        <TableOfContentActions />
        <GlobalStyles />
        <ContentComponents />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          templateFilter={entity =>
            // NOTE: We never want to show the hosting-platform forms in the template create page of baseplate
            entity.spec.type !== 'hosting-platform-form'
          }
          headerOptions={{
            title: 'Templates',
            subtitle:
              "Choose a template to get started. If you can't find what you're looking for, you can create a new template.",
          }}
          components={{
            TaskPageComponent: () => <OngoingTask />,
            EXPERIMENTAL_TemplateWizardPageComponent: p => (
              <TemplateWizardPage {...p} />
            ),
            TemplateCardComponent: ({ template }) => {
              return (
                <EntityLink
                  entity={template}
                  variant="thumbnail"
                  showFavoriteButton
                />
              );
            },
          }}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <AdminAccountExtension />
        <ServersDropdownExtension />
        <CalloutFieldExtension />
        <SelectFieldFromApiExtension />
        <EntityPickerFieldExtension />
        <MarkdownContentExtension />
        <DateExtension />
        <LinksAndActionsExtension />
        <EntityTagsPickerExtension />
        <ToolCategoryPickerExtension />
        <SelectableRadioCardExtension />
        <ImageUploadExtension />
        <GithubTokenFieldExtension />
        <MultiStringFieldExtension />
        <CustomSupportLinkExtension />
        <ProductRelationPickerFieldExtension />
        <CurrentEntityFieldExtension />
        <SupportLinkFieldExtension />
        <DependencyDisplayFieldExtension />
        <AzureTokenFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/snyk" element={<SnykPage />} />
    <Route path="/fossid" element={<FossIdPage />} />
    <Route path="/elastic" element={<ElasticPage />} />
    <Route path="/github" element={<GithubPage />} />
    <Route path="jira" element={<JiraPage />} />
    <Route
      path="/vulnerability-gamification"
      element={<VulnerabilityGamificationCard />}
    />
    {/* Possibly check for the entity type before we show the Cloud Page */}
    <Route path="/cloud" element={<CloudPage />} />
    <Route path={cloudCreateFormUrlPattern} element={<CloudForm />} />
    <Route
      path="/nexus-databricks-storage-credentials-and-external-locations"
      element={<NexusDatabricksStorageCredentialsExternalLocationsPage />}
    />
    <Route
      // Temporary to keep the plugin available on the same URL
      // while it is not fully decommissioned
      path="/catalog/default/tool/nexus"
      element={<NexusIngestionPage />}
    />
    <Route path="/novus" element={<NovusPageLegacy />} />
    {/* Two route for the same component, in case productId is undefined the other route is available */}
    <Route
      path="/request-access/workspace/:workspace/api/:api/environment/:environment/owner/:productId"
      element={<ApprovalFlowPage />}
    />
    <Route
      path="/request-access/workspace/:workspace/api/:api/environment/:environment"
      element={<ApprovalFlowPage />}
    />
    <Route path="/approval-flow" element={<ApprovalFlowPage />} />
    <Route path="/register-api" element={<RegisterApiPage />} />
    <Route path="/asma" element={<AsmaPage />} />
    <Route path="/mac-build-cloud" element={<MacBuildCloudPage />} />
    <Route
      path="/ai"
      element={<Navigate to="/catalog/default/tool/ai-assistant" replace />}
    />
    <Route path="/genai" element={<LangChainPage />} />
    <Route path="/nasuni-form" element={<NasuniFormPage />} />
    <Route path="/zabbix-management" element={<ZabbixPage />} />
    <Route path="/vm-snapshot" element={<VmSnapshotPage />} />
    <Route path="/vm-creation" element={<CreationOpenPage />} />
    <Route path="/vm-decommission" element={<DecommissionPage />} />
    <Route
      path="/pagerduty-user-creation"
      element={<PagerDutyPageContainer />}
    />
    <Route path="/particles" element={<ParticlesPage />} />
    <Route path="/api-linter" element={<LinterPage />} />
    <Route path="/api-metrics" element={<ApiMetricsPage />} />
    <Route path="/deployments" element={<ApiDeploymentLogsPage />} />
    <Route path="/admin" element={<DevToolsPage />}>
      <RequirePermission permission={adminPageReadPermission}>
        <AdminPage />
      </RequirePermission>
    </Route>
    <Route path="/tools" element={<ToolsExplorerPage />} />
    <Route path="/products" element={<ProductsExplorerPage />} />
    <Route path="/docs-analytics" element={<DocsAnalyticsPage />} />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DocumentationPage />
    </Route>
    <Route
      path="/people-to-product"
      element={
        <RequirePermission permission={peopleLeaderAccess}>
          <PeopleToProductPage />
        </RequirePermission>
      }
    />
    <Route path="/admin" element={<CloudAdminPage />} />
    <Route path="/learning-and-development" element={<LearnDevPage />}>
      <Route path="/communities" element={<CommunitiesPage />} />
      <Route path="/overview" element={<CommunitiesPage />} />
    </Route>
    <Route path="/communities" element={<LearnDevPage />}>
      <Route path="/overview" element={<CommunitiesPage />} />
    </Route>
    <Route path="/plugin-app-config" element={<PluginAppConfigPage />} />
    <Route path="/templates" element={<BaseplateTemplatesPage />} />
    <Route path="/esp-status" element={<EspStatusPage />} />
    <Route path="/esp-metrics" element={<EspMetricsPage />} />
    <Route path="/hosting-platform" element={<HostingPlatform />}>
      <Route path="/mssql" element={<MssqlPage />} />
      <Route path="/rabbitmq*" element={<RabbitmqPage />} />
      <Route path="/novus*" element={<NovusPage />} />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name/hosting-platform/service-instances"
      element={<HPServiceTypeInstance />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name/hosting-platform/service-catalog/:serviceName?"
      element={<ServiceForm />}
    >
      <ScaffolderFieldExtensions>
        <CalloutFieldExtension />
        <SelectFieldFromApiExtension />
        <EntityPickerFieldExtension />
        <MarkdownContentExtension />
        <DateExtension />
        <LinksAndActionsExtension />
        <EntityTagsPickerExtension />
        <ToolCategoryPickerExtension />
        <SelectableRadioCardExtension />
        <ImageUploadExtension />
        <GithubTokenFieldExtension />
        <ProductRelationPickerFieldExtension />
        <CurrentEntityFieldExtension />
        <SupportLinkFieldExtension />
        <DependencyDisplayFieldExtension />
        <CustomSupportLinkExtension />
        <AzureTokenFieldExtension />
        <AutoCompleteFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <QueryClientProvider client={queryClient} contextSharing>
        <UserContextProvider>
          <SupportProvider>
            <SearchModalContextProvider>
              <Banners />
              <FeatureEnabledForEntityProvider>
                <Root>{routes}</Root>
              </FeatureEnabledForEntityProvider>
              <GitHubConfigContextProvider>
                <GithubCopilotPopupCard />
              </GitHubConfigContextProvider>
            </SearchModalContextProvider>
          </SupportProvider>
        </UserContextProvider>
      </QueryClientProvider>
      <FiveStarFeedbackToastifyPathTrigger />
      <FiveStarFeedbackToastifyUseBPTrigger />
      <ToastContainer
        enableMultiContainer
        containerId="behind-side-panel"
        style={{
          zIndex: 1199, // The side panel has 1200
        }}
        pauseOnFocusLoss={false}
        draggable={false}
        closeOnClick={false}
      />
    </AppRouter>
  </>,
);
