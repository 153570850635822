import { IconComponent } from '@backstage/core-plugin-api';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useStyles } from './EntityIcon.styles';

export const IdentityIcon: React.FC<IconComponent> = props => {
  const classes = useStyles();
  const iconClassName = `svg-icon-32`;

  return (
    <SvgIcon {...props}>
      <svg
        width={32}
        height={32}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['it-component-layer-3']}
        />
        <path
          d="M12.3 5.85C13.5431 5.85 14.55 6.85687 14.55 8.1V10.5H15.9V8.1C15.9 6.11156 14.2884 4.5 12.3 4.5C10.3115 4.5 8.69995 6.11156 8.69995 8.1V10.5H10.05V8.1C10.05 6.85687 11.0568 5.85 12.3 5.85Z"
          className={classes['it-component-layer-2']}
        />
        <path
          d="M14.55 9.89999H10.05H8.7H7.8C6.80719 9.89999 6 10.7072 6 11.7V17.1C6 18.0928 6.80719 18.9 7.8 18.9H16.8C17.7928 18.9 18.6 18.0928 18.6 17.1V11.7C18.6 10.7072 17.7928 9.89999 16.8 9.89999H15.9H14.55Z"
          className={classes['it-component-layer-1']}
        />
      </svg>
    </SvgIcon>
  );
};
