import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';

export const featureFlags = {
  // placeholder: 'cloud-plugin-placeholder', // we need at least one feature flag
  formRedesign: 'cloud-form-redesign',
} as const;

export const useFeatureFlag = () => {
  const featureFlagsApi = useApi(featureFlagsApiRef);

  return Object.fromEntries(
    Object.entries(featureFlags).map(([key, value]) => [
      key,
      featureFlagsApi.isActive(value),
    ]),
  ) as Record<keyof typeof featureFlags, boolean>;
};
