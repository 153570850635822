import {
  Reingestion,
  ReingestionProps,
} from '@lego/plugin-baseplate-core-components';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { EntitySearchBar } from '../entity-search';

interface Props {
  pickers?: React.ReactNode[];
  searchPlaceholder?: string;
  reingestion?: ReingestionProps;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
  },
  searchWrapper: {
    flexGrow: 1,
  },
  pickerWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    minWidth: 250,
    '& > div': { width: 230 },
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
  },
  reingestionWrapper: {
    marginTop: 'auto',
  },
}));

export const FilterRowLayout = ({
  pickers,
  searchPlaceholder,
  reingestion,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.searchWrapper}>
        <EntitySearchBar placeholder={searchPlaceholder ?? 'Search'} />
      </div>
      <div className={classes.actionWrapper}>
        <div className={classes.pickerWrapper}>{pickers}</div>
        {reingestion && (
          <div className={classes.reingestionWrapper}>
            <Reingestion {...reingestion} />
          </div>
        )}
      </div>
    </div>
  );
};
