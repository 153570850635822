import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const NovusPlugin = createPlugin({
  id: 'novus-hp',
  featureFlags: [{ name: 'hosting-platform-novus' }],
  routes: {
    root: rootRouteRef,
  },
});

export const NovusPage = NovusPlugin.provide(
  createRoutableExtension({
    name: 'NovusPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

export const NovusInstancePage = NovusPlugin.provide(
  createComponentExtension({
    name: 'NovusInstancePage',
    component: {
      lazy: () => import('./components/InstancePage').then(m => m.InstancePage),
    },
  }),
);
