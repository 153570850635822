import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { instanceRouteRef, rootRouteRef } from './routes';
import { SupportLinkExtension } from './shared';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HostingPlatformApiClient, HostingPlatformApiRef } from './api';

export const hostingPlatformPlugin = createPlugin({
  id: 'hosting-platform',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: HostingPlatformApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new HostingPlatformApiClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const HostingPlatform = hostingPlatformPlugin.provide(
  createRoutableExtension({
    name: 'HostingPlatform',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

export const HPServiceTypeInstance = hostingPlatformPlugin.provide(
  createRoutableExtension({
    name: 'HPServiceTypeInstance',
    component: () =>
      import('./components/InstancePageRouter').then(m => m.InstancePageRouter),
    mountPoint: instanceRouteRef,
  }),
);

/**
 * Loads the Hosting Platforms Request Service Form page.
 *
 * @public
 */
export const ServiceForm = hostingPlatformPlugin.provide(
  createComponentExtension({
    name: 'ServiceForm',
    component: {
      lazy: () => import('./components/ServiceForm').then(m => m.ServiceForm),
    },
  }),
);

export const SupportLinkFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SupportLinkFieldExtension',
    component: SupportLinkExtension,
  }),
);
