import {
  ANNOTATION_EDIT_URL,
  Entity,
  RELATION_CHILD_OF,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PARENT_OF,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  CopyTextButton,
  Link,
  MarkdownContent,
} from '@backstage/core-components';
import { AboutField } from '@backstage/plugin-catalog';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { IntegrationApiCallout } from '@lego/plugin-baseplate-amma';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';
import {
  isAPI,
  isApplication,
  isDomain,
  isOrganization,
  isProduct,
  isSubDomain,
} from '@lego/plugin-baseplate-common';
import {
  Button,
  EntityLink,
  LinkCard,
} from '@lego/plugin-baseplate-core-components';
import { PostmanCollectionComponent } from '@lego/plugin-baseplate-openapi-to-postmancollection';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';

interface props {
  entity: Entity;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    width: '100%',
    height: '50%',
    margin: `${theme.spacing(2)}px 0`,
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
    '& a': {
      display: 'block',
    },
  },
  description: {
    wordBreak: 'break-word',
  },
  smallContainer: {
    width: '50%',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%',
    marginTop: '1rem',
  },
}));

export function BaseplateAboutContent({ entity }: props) {
  const classes = useStyles();
  const sortEntities = (entities: Entity[]) =>
    entities.sort((a, b) => a.metadata.title!.localeCompare(b.metadata.title!));

  const ownedByRelations = sortEntities(
    useRelatedEntities(entity, {
      type: RELATION_OWNED_BY,
    }).entities ?? [],
  );
  const childOfRelation = sortEntities(
    useRelatedEntities(entity, {
      type: RELATION_CHILD_OF,
    }).entities ?? [],
  );

  const applicationPartOf = sortEntities(
    useRelatedEntities(entity, {
      type: RELATION_PART_OF,
      kind: 'application',
    }).entities ?? [],
  );

  const subDomainOwnerOf = sortEntities(
    useRelatedEntities(entity, {
      type: RELATION_OWNER_OF,
      kind: 'product',
    }).entities ?? [],
  );

  const groupParentOf = sortEntities(
    useRelatedEntities(entity, {
      type: RELATION_PARENT_OF,
      kind: 'group',
    }).entities ?? [],
  );

  const entityMetadataEditUrl =
    entity.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const titleOfChildrenAboutField = useMemo(() => {
    if (isOrganization(entity)) {
      return 'Domains';
    } else if (isDomain(entity)) {
      return 'Sub-domains';
    } else if (isSubDomain(entity)) {
      return 'Products';
    }
    return '';
  }, [entity]);

  const workspaceToConnect =
    entity?.metadata?.annotations !== undefined
      ? entity?.metadata?.annotations['baseplate.legogroup.io/raw-namespace']
      : undefined;

  const apiToConnect =
    entity?.metadata?.annotations !== undefined
      ? entity?.metadata?.annotations['baseplate.legogroup.io/raw-name']
      : undefined;

  const productId = ownedByRelations[0]?.metadata?.name || undefined;

  const environment = entity.spec?.environment;

  return (
    <>
      <Grid container>
        {/* Only showing the description for Product pages for now.
            Descriptions for domains and subdomains are only short one-line sentences.
            So leanix doesn't expose/provide that data. If added later just remove "entity.kind !== 'Group'". */}
        {!isOrganization(entity) && entity.kind !== 'Group' && (
          <AboutField label="Description" gridSizes={{ xs: 12 }}>
            {entity.kind.toLocaleLowerCase() === 'api' &&
              entity.spec?.environment === AMMA_ENVIRONMENT.INTEGRATION && (
                <IntegrationApiCallout />
              )}
            {(entity?.metadata?.description && (
              <MarkdownContent
                className={classes.description}
                content={entity?.metadata?.description}
              />
            )) || (
              <Typography
                variant="body1"
                paragraph
                className={classes.description}
              >
                Description not found. Add a description for this {entity.kind}
                <Link
                  target="_blank"
                  to={
                    isAPI(entity)
                      ? '/docs/default/Component/amma/'
                      : entityMetadataEditUrl ?? ''
                  }
                >
                  {' '}
                  here.
                </Link>
              </Typography>
            )}
          </AboutField>
        )}

        {isProduct(entity) &&
          entity?.metadata?.links &&
          entity?.metadata?.links.length > 0 && (
            <AboutField label="" gridSizes={{ xs: 12 }}>
              <Grid className={classes.linksContainer}>
                {entity.metadata.links.map((link, index) => (
                  <LinkCard title={link.title} url={link.url} key={index} />
                ))}
              </Grid>
            </AboutField>
          )}

        {(isProduct(entity) || isAPI(entity) || isApplication(entity)) && (
          <AboutField label="Owner" value="No Owner" gridSizes={{ xs: 12 }}>
            <>
              <Grid className={classes.smallContainer}>
                {ownedByRelations?.map((relatedEntity: Entity) => (
                  <EntityLink
                    variant="link"
                    entity={relatedEntity}
                    key={relatedEntity.metadata.name}
                  />
                ))}
              </Grid>
            </>
          </AboutField>
        )}
        <>
          {(isDomain(entity) || isSubDomain(entity)) && (
            <AboutField
              label="Sub-domain in:"
              value="No Owner"
              gridSizes={{ xs: 12 }}
            >
              <Grid className={classes.container}>
                {childOfRelation?.map((relatedEntity: Entity) => (
                  <EntityLink
                    variant="link"
                    entity={relatedEntity}
                    key={relatedEntity.metadata.name}
                  />
                ))}
              </Grid>
            </AboutField>
          )}
          {(isDomain(entity) || isOrganization(entity)) && (
            <AboutField
              label={
                isOrganization(entity)
                  ? `${titleOfChildrenAboutField} in ${entity.metadata.name}` ||
                    'Default Name'
                  : `${titleOfChildrenAboutField} in ${entity.metadata.title}` ||
                    'Default Title'
              }
              value={`No ${titleOfChildrenAboutField} found`}
              gridSizes={{ xs: 12 }}
            >
              <Grid className={classes.container}>
                {groupParentOf?.map((relatedEntity: Entity) => (
                  <EntityLink
                    variant="thumbnail"
                    entity={relatedEntity}
                    key={relatedEntity.metadata.name}
                  />
                ))}
              </Grid>
            </AboutField>
          )}

          {isSubDomain(entity) && (
            <AboutField
              label={`Products in ${entity.metadata.title}`}
              value="No product found"
              gridSizes={{ xs: 12 }}
            >
              <Grid className={classes.container}>
                {subDomainOwnerOf?.map((relatedEntity: Entity) => (
                  <EntityLink
                    variant="thumbnail"
                    entity={relatedEntity}
                    key={relatedEntity.metadata.name}
                  />
                ))}
              </Grid>
            </AboutField>
          )}
        </>

        {isAPI(entity) && (
          <>
            <AboutField
              label="Application"
              value="No application found"
              gridSizes={{ xs: 12 }}
            >
              <Grid className={classes.container}>
                {applicationPartOf?.map((relatedEntity: Entity) => (
                  <EntityLink
                    variant="link"
                    entity={relatedEntity}
                    key={relatedEntity.metadata.name}
                  />
                ))}
              </Grid>
            </AboutField>
            <>
              <AboutField
                label="Identity"
                value={entity?.spec?.identity as string}
                gridSizes={{ xs: 6 }}
              />
              <CopyTextButton
                text={entity?.spec?.identity as string}
                tooltipText="Copied Identity to Clipboard"
                arial-label="Copy the Identity to the clipboard"
              />
            </>
            <AboutField
              label="Type"
              value={entity?.spec?.type as string}
              gridSizes={{ xs: 12 }}
            />
            <div
              style={{
                marginTop: '1rem',
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              {entity.spec?.type === 'openapi' && (
                <PostmanCollectionComponent />
              )}
            </div>
          </>
        )}
      </Grid>
    </>
  );
}
