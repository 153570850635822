import { EntityMeta } from '@backstage/catalog-model';
import { GitHubIcon } from '@backstage/core-components';
import { TaskStep } from '@backstage/plugin-scaffolder-common';
import {
  ScaffolderOutputText,
  ScaffolderTaskOutput,
  ScaffolderTaskStatus,
} from '@backstage/plugin-scaffolder-react';
import {
  Button,
  Callout,
  Spinner,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useMemo, useState } from 'react';

const useStyles = baseplateStyles(theme => ({
  buttonBar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

type TaskStepWithStatus = TaskStep & {
  status: ScaffolderTaskStatus;
};

type ScaffolderOutputTextWithButtons = ScaffolderOutputText & {
  backButton?: {
    label: string;
    url: string;
  };
};

export type CustomScaffolderTaskOutput = Omit<ScaffolderTaskOutput, 'text'> & {
  text: ScaffolderOutputTextWithButtons[];
};

export const TemplateOutputs = (props: {
  output?: CustomScaffolderTaskOutput;
  completed: boolean;
  error: Error | undefined;
  metadata?: EntityMeta;
  taskId?: string;
  steps: TaskStepWithStatus[];
  cancelEnabled: boolean;
  startOver: (url?: string) => void;
}) => {
  const {
    output,
    completed,
    error,
    taskId,
    steps,
    metadata,
    cancelEnabled,
    startOver,
  } = props;
  const classes = useStyles();

  const [textOutputIndex, setTextOutputIndex] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (textOutputIndex === undefined && output?.text) {
      const defaultIndex = output.text.findIndex(
        (t: ScaffolderOutputText) => t.default,
      );

      setTextOutputIndex(defaultIndex >= 0 ? defaultIndex : 0);
    }
  }, [textOutputIndex, output]);

  const textOutput = useMemo(
    () =>
      textOutputIndex !== undefined ? output?.text?.[textOutputIndex] : null,
    [output, textOutputIndex],
  );

  const backButton = useMemo(
    () =>
      textOutputIndex !== undefined
        ? output?.text?.[textOutputIndex].backButton
        : undefined,
    [output, textOutputIndex],
  );

  if (!completed) {
    return (
      <Box py={2} className="narrow-layout">
        <Spinner />
      </Box>
    );
  }

  if (error) {
    const stepWithError = steps.find(step => step.status === 'failed');
    const title = metadata?.title ?? 'Unknown template';
    return (
      <Box py={2} className="narrow-layout">
        <Callout
          type="error"
          title={
            stepWithError?.name
              ? `Error in step: ${stepWithError?.name}`
              : 'Something went wrong.'
          }
          body={
            <>
              <Typography variant="body1">{error.message}</Typography>
              <div className={classes.buttonBar}>
                <div>
                  <Button
                    target="_blank"
                    variant="secondary"
                    href={`https://github.com/LEGO/baseplate/issues/new?assignees=&labels=&projects=&template=bug_report.md&title=Error in Task ${title}: ${
                      error?.name
                    }&body=${encodeURIComponent(
                      `Hi, I faced an error in the software templates that i would like to report 😊\n\n` +
                        `## Task Details\n` +
                        `- **Task ID**: \`${taskId}\`\n` +
                        `- **Task Name**: \`${title}\`\n\n` +
                        `- **Step**: \`${stepWithError?.name}\`\n\n` +
                        `## Error Details\n` +
                        `\`\`\`json\n` +
                        `${JSON.stringify(error, null, 2)}\n` +
                        `\`\`\`\n`,
                    )}`}
                  >
                    <GitHubIcon /> Report issue
                  </Button>
                </div>
                <div>
                  <Button
                    variant="secondary"
                    disabled={cancelEnabled}
                    onClick={() => startOver(backButton?.url)}
                  >
                    <ArrowBackIcon style={{ paddingRight: 4 }} />
                    {backButton?.label ?? 'Back'}
                  </Button>
                </div>
              </div>
            </>
          }
        />
      </Box>
    );
  }

  if (!output) {
    return null;
  }

  return (
    <Box className="narrow-layout" paddingTop={2}>
      {textOutput ? (
        <Callout
          title="Next steps"
          body={
            <Box py={2}>
              <Box height="100%">
                <Markdown
                  options={{
                    overrides: {
                      p: {
                        component: Typography,
                        props: {
                          variant: 'body1',
                        },
                      },
                    },
                  }}
                >
                  {textOutput.content ?? ''}
                </Markdown>
              </Box>
              <div className={classes.buttonBar}>
                <div>
                  <Button
                    variant="secondary"
                    disabled={cancelEnabled}
                    onClick={() => startOver(backButton?.url)}
                  >
                    <ArrowBackIcon style={{ paddingRight: 4 }} />
                    {backButton?.label ?? 'Back'}
                  </Button>
                </div>
              </div>
            </Box>
          }
        />
      ) : null}
    </Box>
  );
};
