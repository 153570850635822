import React, { useState } from 'react';
import { useManageCommunityStyles } from '../ManageCommunity/ManageCommunity.styles';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { CreateCommunityForm } from '../CreateCommunity/CreateCommunityForm';
import { Formik } from 'formik';
import { CommunityV2 } from '@lego/plugin-baseplate-communities-common';
import { initiateCommunityFormValues } from '../CreateCommunity/utils';
import { CreateCommunitySchema } from '../CreateCommunity/CreateCommunitySchema';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { communitiesV2ApiRef } from '../../apiV2';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommunityEntityV2 } from '../../types';
import { useEntity } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';

export const EditCommunityPage = () => {
  const navigate = useNavigate();
  const styles = useManageCommunityStyles();
  const { entity }: { entity: CommunityEntityV2 } = useEntity();
  const communitiesV2Api = useApi(communitiesV2ApiRef);
  const [formValues, setFormValues] = useState<CommunityV2>(
    initiateCommunityFormValues(),
  );
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  useAsync(async () => {
    if (!entity) return undefined;
    const baseUrl = await discoveryApi.getBaseUrl('communities-v2');
    const response = await fetchApi.fetch(
      `${baseUrl}/${entity.metadata.dbId}`,
      {
        method: 'GET',
      },
    );

    const result = (await response.json()) as CommunityV2;
    // todo: find a better solution. This is a temporary fix for the issue because formik cannot handle null values, only undefined or empty string
    result.teamsChannelLink = result.teamsChannelLink || undefined;
    result.about = result.about || '';
    result.techdocsEntityRef = result.techdocsEntityRef || undefined;
    return setFormValues(result);
  }, [entity]);

  const handleOnSubmit = async (values: CommunityV2) => {
    try {
      const response = await communitiesV2Api.updateCommunity(values);
      if (response && response.id) {
        navigate('/communities/overview/update/success');
      } else {
        throw new Error('Something went wrong. Please try again!');
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <div className={styles.mngCommContainer}>
      <div className={styles.mngCommHeadContainer}>
        <div className="displayFlexRow">
          <Typography variant="h2">Edit your community</Typography>
        </div>
      </div>
      <Formik
        initialValues={formValues}
        validationSchema={CreateCommunitySchema}
        onSubmit={handleOnSubmit}
      >
        <CreateCommunityForm
          formValues={formValues}
          setFormValues={setFormValues}
          hideWelcomeMessage
          buttonTitle="Save"
        />
      </Formik>
    </div>
  );
};
