import React from 'react';
import {
  Dropdown,
  EntityDropdown,
  FormStepTitle,
  Input,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../utils/types';
import { getCloudAccountName } from '../utils/getCloudAccountName';
import { useFormikContext } from 'formik';

import { stringifyEntityRef } from '@backstage/catalog-model';

import { Entity } from '@backstage/catalog-model';
import { ANNOTATION_LEANIX_APPLICATION_WSBNO } from '@lego/plugin-baseplate-common';

const getEmailFromEntity = (entity: Entity): string | undefined =>
  (entity?.spec?.profile as { email: string })?.email?.toString();

export const StepInformation = ({
  values,
  updateValue,
  productId,
}: StepProps & { productId: string }) => {
  const { errors, touched, handleBlur } =
    useFormikContext<StepProps['values']>();

  return (
    <Grid>
      <FormStepTitle
        formTitle={`Create Cloud Account for ${productId}`}
        stepTitle="Account information"
        step={2}
      />
      <Box display="flex" flexDirection={'column'} style={{ gap: 20 }}>
        <Box>
          <Box display="flex" mb={1} style={{ gap: 10 }}>
            <Input
              label="Name"
              placeholder="name"
              InputProps={{
                startAdornment: <Typography variant="body1">lego-</Typography>,
                endAdornment: <Typography variant="body1">-</Typography>,
              }}
              value={values.name}
              onChange={e => {
                updateValue('name', e.target.value);
              }}
              onBlur={handleBlur}
              error={!!(errors.name && touched.name)}
              errorText={
                errors.name && touched.name ? `* ${errors.name}` : undefined
              }
              required
            />
            <Box sx={{ width: 290 }}>
              <Dropdown
                label="Environment"
                value={values.environment}
                placeholder={values.environment}
                options={[
                  { label: 'sandbox', value: 'sandbox' },
                  { label: 'test', value: 'test' },
                  { label: 'dev', value: 'dev' },
                  { label: 'qa', value: 'qa' },
                  { label: 'uat', value: 'uat' },
                  { label: 'staging', value: 'staging' },
                  { label: 'prod', value: 'prod' },
                ]}
                onChange={e => {
                  updateValue('environment', e.target.value as string);
                }}
                onBlur={handleBlur}
                error={!!(errors.environment && touched.environment)}
                errorText={
                  errors.environment && touched.environment
                    ? `* ${errors.environment}`
                    : undefined
                }
                required
              />
            </Box>
          </Box>
          <Typography variant="caption" color="secondary">
            Enter a name that helps colleagues recognize the purpose of this
            cloud account.
          </Typography>
          <Typography variant="body2">
            Final account name:{' '}
            <strong>
              {getCloudAccountName(values.name, values.environment)}
            </strong>
          </Typography>
        </Box>
        <Box>
          <EntityDropdown
            kind="Application"
            value={values.applicationRef}
            handleChange={entity => {
              {
                updateValue('applicationRef', stringifyEntityRef(entity));
                updateValue('applicationId', entity.metadata.name);
                updateValue('applicationName', entity.metadata.title);

                const wbsNumber =
                  entity.metadata.annotations?.[
                    ANNOTATION_LEANIX_APPLICATION_WSBNO
                  ];
                updateValue('applicationWBSNumber', wbsNumber);
              }
            }}
            filter={{ 'spec.owner': `product:default/${productId}` }}
            errorMessage={
              errors.applicationId && touched.applicationId
                ? `* ${JSON.stringify(errors.applicationId).replace(/"/g, '')}`
                : undefined
            }
            required
          />
        </Box>
        <Box>
          <Input
            label="Purpose"
            value={values.purpose}
            onChange={e => updateValue('purpose', e.target.value)}
            multiline
            onBlur={handleBlur}
            error={!!(errors.purpose && touched.purpose)}
            errorText={
              errors.purpose && touched.purpose
                ? `* ${errors.purpose}`
                : undefined
            }
            required
          />
          <Typography variant="caption" color="secondary">
            Describe what this account is used for. To be able to figure out
            what this account does in 3 years.
          </Typography>
        </Box>
        <Box>
          <EntityDropdown
            kind="User"
            label="Owner"
            value={values.ownerRef}
            handleChange={entity => {
              updateValue('ownerRef', stringifyEntityRef(entity));
              updateValue('owner', getEmailFromEntity(entity));
            }}
            errorMessage={
              errors.owner && touched.owner
                ? `* ${JSON.stringify(errors.owner).replace(/"/g, '')}`
                : undefined
            }
            required
          />
        </Box>
        <Box>
          <EntityDropdown
            kind="User"
            label="Backup contact"
            value={values.backupContactRef}
            handleChange={entity => {
              updateValue('backupContactRef', stringifyEntityRef(entity));
              updateValue('backupContact', getEmailFromEntity(entity));
            }}
            errorMessage={
              errors.backupContact && touched.backupContact
                ? `* ${JSON.stringify(errors.backupContact).replace(/"/g, '')}`
                : undefined
            }
            required
          />
        </Box>
        <Box>
          <Input
            label="MS Teams Channel Notification"
            placeholder="Custom Notifications <...legogroup.onmicrosoft.com@emea.teams.ms>"
            value={values.notificationEndpoint}
            onChange={e => {
              updateValue('notificationEndpoint', e.target.value);
            }}
            onBlur={handleBlur}
            error={
              !!(errors.notificationEndpoint && touched.notificationEndpoint)
            }
            errorText={
              errors.notificationEndpoint && touched.notificationEndpoint
                ? `* ${errors.notificationEndpoint}`
                : undefined
            }
            required
          />
        </Box>
      </Box>
    </Grid>
  );
};
