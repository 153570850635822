import { useMemo, useState, useEffect } from 'react';
import { TableRowType } from '../types';

export const usePagination = <T>(
  enablePagination: boolean | undefined,
  filteredData: TableRowType<T>[],
) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(0);
  }, [filteredData]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = useMemo(
    () =>
      page > 0
        ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length)
        : 0,
    [filteredData, page, rowsPerPage],
  );

  const paginatedData = useMemo(() => {
    if (!enablePagination) return filteredData;
    return filteredData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [page, rowsPerPage, filteredData, enablePagination]);

  return {
    page,
    emptyRows,
    paginatedData,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
