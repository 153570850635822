/* tslint:disable */
/* eslint-disable */
/**
 * AWSLandingZone
 * AWS Cloud Landing Zone Account Factory and Management REST APIs.                                         This API specification also applies to our AWS API Gateway China partition API.                                         The only difference is that China does not use Cognito for Authorization.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: 12e6b798.o365.corp.LEGO.com@emea.teams.ms
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BudgetAlert } from './BudgetAlert';
import {
  BudgetAlertFromJSON,
  BudgetAlertFromJSONTyped,
  BudgetAlertToJSON,
  BudgetAlertToJSONTyped,
} from './BudgetAlert';

/**
 *
 * @export
 * @interface AwsAccountCreationRequestBodyBudgetAlertsInner
 */
export interface AwsAccountCreationRequestBodyBudgetAlertsInner {
  /**
   * The threshold that's associated with a NotificationType.
   * @type {number}
   * @memberof AwsAccountCreationRequestBodyBudgetAlertsInner
   */
  threshold: number;
  /**
   * The type of a notification. Must be one of the allowed values
   * @type {string}
   * @memberof AwsAccountCreationRequestBodyBudgetAlertsInner
   */
  notificationType: AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum;
}

/**
 * @export
 */
export const AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum =
  {
    ACTUAL: 'ACTUAL',
    FORECASTED: 'FORECASTED',
  } as const;
export type AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum =
  (typeof AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum)[keyof typeof AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum];

/**
 * Check if a given object implements the AwsAccountCreationRequestBodyBudgetAlertsInner interface.
 */
export function instanceOfAwsAccountCreationRequestBodyBudgetAlertsInner(
  value: object,
): value is AwsAccountCreationRequestBodyBudgetAlertsInner {
  if (!('threshold' in value) || value['threshold'] === undefined) return false;
  if (!('notificationType' in value) || value['notificationType'] === undefined)
    return false;
  return true;
}

export function AwsAccountCreationRequestBodyBudgetAlertsInnerFromJSON(
  json: any,
): AwsAccountCreationRequestBodyBudgetAlertsInner {
  return AwsAccountCreationRequestBodyBudgetAlertsInnerFromJSONTyped(
    json,
    false,
  );
}

export function AwsAccountCreationRequestBodyBudgetAlertsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AwsAccountCreationRequestBodyBudgetAlertsInner {
  if (json == null) {
    return json;
  }
  return {
    threshold: json['threshold'],
    notificationType: json['notificationType'],
  };
}

export function AwsAccountCreationRequestBodyBudgetAlertsInnerToJSON(
  json: any,
): AwsAccountCreationRequestBodyBudgetAlertsInner {
  return AwsAccountCreationRequestBodyBudgetAlertsInnerToJSONTyped(json, false);
}

export function AwsAccountCreationRequestBodyBudgetAlertsInnerToJSONTyped(
  value?: AwsAccountCreationRequestBodyBudgetAlertsInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    threshold: value['threshold'],
    notificationType: value['notificationType'],
  };
}
