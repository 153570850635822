import React from 'react';
import { type ReactElement } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from '@material-ui/core';
import {
  ChartSummary,
  ChartWrapper,
  axisBottom,
  axisLeft,
  legend,
  xScale,
  yScale,
  useChartColors,
} from './utils';
import { BaseChartProps } from './types';

export type LineProps = BaseChartProps &
  React.ComponentProps<typeof ResponsiveLine>;

export function LineChart({
  data,
  summary,
  height = 300,
  showLegend,
  colorScheme = 'sequential-green',
  ...props
}: LineProps): ReactElement {
  const { palette } = useTheme();
  const { theme, colors } = useChartColors(palette.type, colorScheme);

  return (
    <div>
      {summary && <ChartSummary value={summary.value} unit={summary.unit} />}
      <ChartWrapper height={height}>
        <ResponsiveLine
          data={data}
          xScale={xScale}
          yScale={yScale}
          yFormat=" >-.2f"
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          lineWidth={3}
          axisTop={null}
          curve="catmullRom"
          pointSize={7}
          margin={{ top: 10, right: 10, bottom: 65, left: 50 }}
          pointLabel="data.yFormatted"
          colors={colors}
          enableSlices="x"
          legends={showLegend ? [legend] : undefined}
          motionConfig="gentle"
          theme={theme}
          {...props}
        />
      </ChartWrapper>
    </div>
  );
}
