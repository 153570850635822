import { primitive } from '../../../design-tokens';
import { ChartColorSchemes } from '../types';

export const getColorScheme = (
  mode: 'light' | 'dark',
  colorScheme: ChartColorSchemes,
): string[] => {
  if (mode === 'light') {
    return {
      'sequential-green': [
        primitive.color.green[500],
        primitive.color.green[700],
        primitive.color.green[900],
        primitive.color.green[1100],
        primitive.color.green[300],
      ],
      'sequential-blue': [
        primitive.color.blue[500],
        primitive.color.blue[700],
        primitive.color.blue[900],
        primitive.color.blue[1100],
        primitive.color.blue[300],
      ],
      'sequential-purple': [
        primitive.color.purple[500],
        primitive.color.purple[700],
        primitive.color.purple[900],
        primitive.color.purple[1100],
        primitive.color.purple[300],
      ],
      categorical: [
        primitive.color.green[500],
        primitive.color.blue[500],
        primitive.color.yellow[400],
        primitive.color.purple[700],
        primitive.color.azure[400],
        primitive.color.pink[500],
        primitive.color.red[700],
      ],
      'categorical-2': [
        primitive.color.blue[600],
        primitive.color.pink[500],
        primitive.color.green[500],
        primitive.color.yellow[300],
        primitive.color.purple[700],
        primitive.color.azure[900],
        primitive.color.red[700],
      ],
      'categorical-3': [
        primitive.color.azure[500],
        primitive.color.orange[500],
        primitive.color.pink[600],
        primitive.color.green[600],
        primitive.color.purple[800],
        primitive.color.yellow[200],
        primitive.color.blue[900],
      ],
    }[colorScheme];
  }
  return {
    'sequential-green': [
      primitive.color.green[800],
      primitive.color.green[600],
      primitive.color.green[400],
      primitive.color.green[200],
      primitive.color.green[1000],
      primitive.color.green[1300],
    ],
    'sequential-blue': [
      primitive.color.blue[800],
      primitive.color.blue[600],
      primitive.color.blue[400],
      primitive.color.blue[200],
      primitive.color.blue[1000],
      primitive.color.blue[1300],
    ],
    'sequential-purple': [
      primitive.color.purple[800],
      primitive.color.purple[600],
      primitive.color.purple[400],
      primitive.color.purple[200],
      primitive.color.purple[1000],
      primitive.color.purple[1300],
    ],
    categorical: [
      primitive.color.green[600],
      primitive.color.blue[800],
      primitive.color.yellow[300],
      primitive.color.purple[800],
      primitive.color.azure[800],
      primitive.color.pink[600],
      primitive.color.red[300],
    ],
    'categorical-2': [
      primitive.color.blue[800],
      primitive.color.pink[500],
      primitive.color.green[600],
      primitive.color.yellow[300],
      primitive.color.purple[900],
      primitive.color.azure[300],
      primitive.color.red[800],
    ],
    'categorical-3': [
      primitive.color.azure[700],
      primitive.color.orange[600],
      primitive.color.pink[700],
      primitive.color.green[600],
      primitive.color.purple[800],
      primitive.color.yellow[200],
      primitive.color.blue[300],
    ],
  }[colorScheme];
};
