import React from 'react';
import {
  Callout,
  Dropdown,
  EntityDropdown,
  FormStepTitle,
  Input,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Slider, Typography } from '@material-ui/core';
import { StepProps } from '../utils/types';
import { InputAdornment } from '@mui/material';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useFormikContext } from 'formik';
import { currencyByVendor } from '../utils/currencyByVendor';

interface BudgetAlertProps {
  threshold: number;
  value: number;
  alertType: string;
  onSliderChange: any;
  onDropdownChange: any;
  currency: string;
  monthlyBudget: number;
}

const BudgetAlert: React.FC<BudgetAlertProps> = ({
  threshold,
  value,
  alertType,
  onSliderChange,
  onDropdownChange,
  currency,
  monthlyBudget,
}) => (
  <Box mb={2}>
    <Box display="flex" alignItems="center" style={{ gap: 20 }}>
      <Grid item xs>
        <Typography variant="body2" gutterBottom>
          Budget alert threshold #{threshold}
        </Typography>
        <Box display="flex" alignItems="center" style={{ gap: 20 }}>
          <Slider
            aria-label={`Budget alert threshold #${threshold}`}
            value={value}
            step={10}
            min={0}
            max={100}
            onChange={onSliderChange}
            disabled={threshold === 1}
          />
          <Typography variant="body2">{value}%</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body2" gutterBottom>
          Budget alert trigger #{threshold}
        </Typography>
        <Dropdown
          label=""
          value={alertType}
          options={['actual', 'forecast']}
          onChange={onDropdownChange}
          disabled={threshold === 1}
          required
        />
      </Grid>
    </Box>
    <Typography variant="caption" gutterBottom color="secondary">
      You will receive an email alert when your {alertType} spending reaches{' '}
      {(monthlyBudget * value) / 100} {currency}
    </Typography>
  </Box>
);

export const StepBudget = ({ values, updateValue, productId }: StepProps) => {
  const { errors, touched } = useFormikContext<StepProps['values']>();

  const currency = currencyByVendor(values.vendor);

  const handleSliderChange = (threshold: number, newValue: number) => {
    if (newValue < 1) {
      newValue = 10;
    }
    if (threshold === 2) {
      if (newValue < values.budgetAlertThreshold3) {
        updateValue('budgetAlertThreshold3', newValue);
      }
      updateValue('budgetAlertThreshold2', newValue);
    } else if (threshold === 3) {
      if (newValue > values.budgetAlertThreshold2) {
        updateValue('budgetAlertThreshold2', newValue);
      }
      updateValue('budgetAlertThreshold3', newValue);
    }
  };
  return (
    <Grid>
      <FormStepTitle
        formTitle={`Create Cloud Account for ${productId}`}
        stepTitle="Budget"
        step={3}
      />
      <Box display="flex" flexDirection={'column'} style={{ gap: 20 }}>
        <Box>
          <Input
            label="Monthly budget"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
            value={values.monthlyBudget}
            onChange={e => updateValue('monthlyBudget', e.target.value)}
            error={!!(errors.monthlyBudget && touched.monthlyBudget)}
            errorText={
              errors.monthlyBudget && touched.monthlyBudget
                ? `* ${errors.monthlyBudget}`
                : undefined
            }
            required
          />
        </Box>
        {values.vendor !== 'Azure' && (
          <Box>
            <Callout
              title="Budget alert thresholds: Actual vs Forecast"
              body={
                <>
                  <span>
                    <b>Actual</b> means the minute your cost has exceeded your
                    set budget alert threshold.
                    <br />
                    <b>Forecast</b> is the calculated total spend on this cloud
                    account for the current month.
                  </span>
                </>
              }
            />
          </Box>
        )}
        {values.vendor !== 'Azure' && (
          <Box>
            <BudgetAlert
              threshold={1}
              value={values.budgetAlertThreshold1 || 100}
              alertType={values.budgetAlertThreshold1Type}
              onSliderChange={(
                event: React.ChangeEvent,
                newValue: string | number,
              ) => updateValue('budgetAlertThreshold1', newValue)}
              onDropdownChange={(e: { target: { value: string | number } }) =>
                updateValue('budgetAlertThreshold1Type', e.target.value)
              }
              currency={currency}
              monthlyBudget={values.monthlyBudget}
            />
            <BudgetAlert
              threshold={2}
              value={values.budgetAlertThreshold2 || 80}
              alertType={values.budgetAlertThreshold2Type}
              onSliderChange={(
                event: React.ChangeEvent,
                newValue: string | number,
              ) => handleSliderChange(2, newValue as number)}
              onDropdownChange={(e: { target: { value: string | number } }) =>
                updateValue('budgetAlertThreshold2Type', e.target.value)
              }
              currency={currency}
              monthlyBudget={values.monthlyBudget}
            />
            <BudgetAlert
              threshold={3}
              value={values.budgetAlertThreshold3 || 60}
              alertType={values.budgetAlertThreshold3Type}
              onSliderChange={(
                event: React.ChangeEvent,
                newValue: string | number,
              ) => handleSliderChange(3, newValue as number)}
              onDropdownChange={(e: { target: { value: string | number } }) =>
                updateValue('budgetAlertThreshold3Type', e.target.value)
              }
              currency={currency}
              monthlyBudget={values.monthlyBudget}
            />
          </Box>
        )}
        <Box>
          <EntityDropdown
            label="Budget approver"
            kind="User"
            value={values.budgetApproverRef}
            handleChange={entity => {
              updateValue('budgetApproverRef', stringifyEntityRef(entity));
              updateValue(
                'budgetApprover',
                (
                  entity.spec?.profile as { email: string }
                )?.email.toLowerCase() ?? '',
              );
            }}
            errorMessage={
              errors.budgetApprover && touched.budgetApprover
                ? `* ${JSON.stringify(errors.budgetApprover).replace(/"/g, '')}`
                : undefined
            }
            required
          />
        </Box>
      </Box>
    </Grid>
  );
};
