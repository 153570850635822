import React from 'react';
import { ApiEntity } from '@backstage/catalog-model';
import { type ReactElement } from 'react';
import { ApiDeploymentLogsPage } from '@lego/plugin-baseplate-api-metrics';

export interface DeploymentLogsTabProps {
  type: ApiEntity['spec']['type'];
}

export function DeploymentLogsTab({
  type,
}: DeploymentLogsTabProps): ReactElement | null {
  if (['openapi', 'graphql'].includes(type)) {
    return <ApiDeploymentLogsPage />;
  } else {
    return null;
  }
}
