/* tslint:disable */
/* eslint-disable */
/**
 * AzureLandingZone
 * An API for exposing Azure Landing Zone functionality and data sets.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: azurelandingzone@o365.corp.LEGO.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An object containing properties of an endpoint.
 * @export
 * @interface PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties
 */
export interface PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties {
  /**
   * A name of an notification endpoint.
   * @type {string}
   * @memberof PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties
   */
  endpointName: string;
  /**
   * A value of an notification endpoint.
   * @type {string}
   * @memberof PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties
   */
  endpointValue: string;
}

/**
 * Check if a given object implements the PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties interface.
 */
export function instanceOfPostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties(
  value: object,
): value is PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties {
  if (!('endpointName' in value) || value['endpointName'] === undefined)
    return false;
  if (!('endpointValue' in value) || value['endpointValue'] === undefined)
    return false;
  return true;
}

export function PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesFromJSON(
  json: any,
): PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties {
  return PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesFromJSONTyped(
    json,
    false,
  );
}

export function PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties {
  if (json == null) {
    return json;
  }
  return {
    endpointName: json['endpointName'],
    endpointValue: json['endpointValue'],
  };
}

export function PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesToJSON(
  value?: PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    endpointName: value['endpointName'],
    endpointValue: value['endpointValue'],
  };
}
