import { rootRouteRef } from './routes';
import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { PLUGIN_ID, PLUGIN_NAME } from './constants';

export const asmaPlugin = createPlugin({
  id: PLUGIN_ID,
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'asma-membership' }],
});

export const AsmaPage: () => JSX.Element = asmaPlugin.provide(
  createRoutableExtension({
    name: PLUGIN_NAME,
    component: () => import('./components/AsmaHomepage').then((m) => m.AsmaHomepage),
    mountPoint: rootRouteRef,
  })
);

export const AppleDeveloperProgramTool: () => JSX.Element = asmaPlugin.provide(
  createRoutableExtension({
    name: 'AppleDeveloperProgram',
    component: () =>
      import('./components/AppleDeveloperProgramTool/AppleDeveloperProgramTool').then(
        (m) => m.AppleDeveloperProgramTool
      ),
    mountPoint: rootRouteRef,
  })
);
