import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormValues } from './utils/types';
import { Button } from '@lego/plugin-baseplate-core-components';
import { Box, Container, Typography } from '@material-ui/core';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { StepChooseVendor } from './steps/StepChooseVendor';
import { StepInformation } from './steps/StepInformation';
import { StepBudget } from './steps/StepBudget';
import { StepSummary } from './steps/StepSummary';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { cloudFormValidationSchema } from './utils/cloudFormValidationSchema';
import { StepNavigation } from './steps/StepNavigation';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useCreateCloudAccount } from './utils/useCreateCloudAccount';

const useBaseplateStyles = baseplateStyles(theme => ({
  backgroundColor: {
    backgroundColor: theme.semantic.background.neutral[1],
  },
}));

// Thes are test values for speeding uplocal development
// const testInitialValue =
//   window.location.hostname === 'localhost'
//     ? {
//         name: `deleteme-${window.crypto.randomUUID().slice(0, 4)}`,
//         applicationId: `APP-00803`,
//         applicationName: `AWS Cloud Landing Zone`,
//         purpose:
//           'The cloud account was constructed for testing the UI baseplate during development.',
//         owner: `walid.mouaaouia@LEGO.com`,
//         backupContact: `cole.bittel@LEGO.com`,
//         budgetApprover: `clara.gonzalez@LEGO.com`,
//         notificationEndpoint: `marcstest_channel - Marc Palm Dummy Team <5ec71e85.o365.corp.LEGO.com@emea.teams.ms>`,
//       }
//     : {};

export const cloudCreateFormUrlPattern = `/create-cloud-account/:productId`;
export const getCloudCreateFormUrl = (productId: string) =>
  cloudCreateFormUrlPattern.replace(`:productId`, productId);

export const CloudForm = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const classes = useBaseplateStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState<FormValues>({
    vendor: 'Azure',
    name: '',
    environment: 'test',
    purpose: '',
    monthlyBudget: 700,
    budgetAlertThreshold1: 100,
    budgetAlertThreshold2: 80,
    budgetAlertThreshold3: 60,
    budgetAlertThreshold1Type: 'actual',
    budgetAlertThreshold2Type: 'actual',
    budgetAlertThreshold3Type: 'forecast',
    applicationId: '',
    applicationName: '',
    applicationRef: undefined,
    owner: '',
    backupContact: '',
    budgetApprover: '',
    ownerRef: undefined,
    backupContactRef: undefined,
    budgetApproverRef: undefined,
    notificationEndpoint: ``,
    // ...testInitialValue,
  });

  const { mutateAsync, data, reset, isLoading } = useCreateCloudAccount(values);

  const isFormSubmitted = Boolean(data);

  console.log({ data, isFormSubmitted });

  const activeStep = searchParams.get('step') ?? '1';
  const activeStepInt = isFormSubmitted ? 4 : parseInt(activeStep);
  const totalSteps = 4;

  const changeStep = (step: string) => {
    setSearchParams(prev => {
      prev.set('step', step);
      return prev;
    });
  };

  const updateValue = (
    field: string,
    value: string | number,
    setFieldValue: (field: string, value: string | number) => any,
  ) => {
    setValues(prevValues => ({
      ...prevValues,
      [field]: value,
    }));
    setFieldValue(field, value);
  };

  const stepProps = useCallback(
    (formik: FormikProps<FormValues>) => {
      const { values, setFieldValue, setFieldTouched, errors, touched } =
        formik;
      return {
        values,
        updateValue: (field: string, value: string | number) => {
          updateValue(field, value, setFieldValue);
        },
        setFieldTouched,
        errors,
        touched,
        changeStep,
      };
    },
    [updateValue, changeStep],
  );

  const handleBack = () => {
    const prevStep = activeStepInt - 1;
    if (prevStep >= 1) {
      changeStep(prevStep.toString());
      window.scrollTo(0, 0);
    }
  };

  const handleNext = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    formik: FormikProps<FormValues>,
  ) => {
    event.preventDefault();
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      const nextStep = activeStepInt + 1;
      if (nextStep <= totalSteps) {
        changeStep(nextStep.toString());
        window.scrollTo(0, 0);
      }
    } else {
      const touchedFields = Object.keys(errors).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {} as { [key: string]: boolean });
      void formik.setTouched(touchedFields);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function onSubmitForm(_values: FormValues) {
    try {
      await mutateAsync();
      toast.success('Cloud account created successfully!', {
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('E3465: Error while submitting', error);
      if (typeof error === 'object' && error) {
        toast.error(
          `There was an error:\n${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            'message' in error ? error.message : JSON.stringify(error)
          }. Please try again.`,
        );
      } else {
        toast.error('There was an unknown error. Please try again.');
      }
    }
  }

  if (!productId) {
    // TODO: Error if no product id
    return <Typography>Your URL must contain a productId</Typography>;
  }

  return (
    <Grid
      mt={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.backgroundColor}
      sx={{
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      <Box sx={{ width: '100%' }} mb={2}>
        <StepNavigation
          activeStepInt={activeStepInt}
          isFormSubmitted={isFormSubmitted}
        />
      </Box>
      <Formik
        initialValues={values}
        validationSchema={cloudFormValidationSchema(activeStepInt)}
        onSubmit={onSubmitForm}
      >
        {formik => (
          <>
            <Container maxWidth="sm" className={classes.backgroundColor}>
              <Form>
                {activeStepInt === 1 && (
                  <StepChooseVendor
                    {...stepProps(formik)}
                    productId={productId}
                  />
                )}
                {activeStepInt === 2 && (
                  <StepInformation
                    {...stepProps(formik)}
                    productId={productId}
                  />
                )}
                {activeStepInt === 3 && (
                  <StepBudget {...stepProps(formik)} productId={productId} />
                )}
                {activeStepInt === 4 && (
                  <StepSummary {...stepProps(formik)} productId={productId} />
                )}
                <Box
                  mt={3}
                  mb={3}
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  {!isFormSubmitted && (
                    <Button
                      variant="secondary"
                      onClick={handleBack}
                      disabled={
                        activeStepInt === 1 || formik.isSubmitting || isLoading
                      }
                      sx={{
                        visibility: activeStepInt === 1 ? 'hidden' : 'visible',
                      }}
                    >
                      Back
                    </Button>
                  )}
                  {activeStepInt < totalSteps && !isFormSubmitted && (
                    <Button
                      variant="primary"
                      onClick={event => void handleNext(event, formik)}
                      disabled={formik.isSubmitting || isLoading}
                    >
                      Next
                    </Button>
                  )}
                  {activeStepInt === totalSteps && !isFormSubmitted && (
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={formik.isSubmitting || isLoading}
                    >
                      Create Cloud Account
                    </Button>
                  )}
                  {isFormSubmitted && (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          formik.resetForm();
                          reset();
                          changeStep('1');
                        }}
                      >
                        Create Another Cloud Account
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          formik.resetForm();
                          reset();
                          navigate(
                            `/catalog/default/Product/${productId}/cloud/`,
                          );
                        }}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </Box>
              </Form>
            </Container>
            <ToastContainer position="bottom-center" autoClose={false} />
          </>
        )}
      </Formik>
    </Grid>
  );
};
