import { ConsumedResponse } from '@backstage/errors';
import { parseErrorResponseBody } from './serialization/response';

/**
 * An error thrown as the result of a failed server request.
 *
 * The server is expected to respond in the AmmaErrorResponseBody format.
 *
 * @public
 */
export class AmmaError extends Error {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  additionalProp1?: string;
  additionalProp2?: string;
  additionalProp3?: string;

  /**
   * Constructs an AmmaError based on a failed response.
   *
   * Assumes that the response has already been checked to be not ok. This
   * function consumes the body of the response, and assumes that it hasn't
   * been consumed before.
   */
  static async fromResponse(
    response: ConsumedResponse & { json(): Promise<AmmaError> },
  ): Promise<AmmaError> {
    const data = await parseErrorResponseBody(response);
    const {
      type,
      title,
      status,
      detail,
      instance,
      additionalProp1,
      additionalProp2,
      additionalProp3,
    } = data;

    return new AmmaError(
      type,
      title,
      status,
      detail,
      instance,
      additionalProp1,
      additionalProp2,
      additionalProp3,
    );
  }

  constructor(
    type: string,
    title: string,
    status: number,
    detail: string,
    instance: string,
    additionalProp1?: string,
    additionalProp2?: string,
    additionalProp3?: string,
  ) {
    super(detail);
    this.type = type;
    this.title = title;
    this.status = status;
    this.detail = detail;
    this.instance = instance;
    if (additionalProp1) this.additionalProp1 = additionalProp1;
    if (additionalProp2) this.additionalProp2 = additionalProp2;
    if (additionalProp3) this.additionalProp3 = additionalProp3;
  }
}

export default AmmaError;
