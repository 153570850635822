import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { MsSQLApi } from './MsSQLApi';
import { ApiUtils } from '@lego/plugin-baseplate-core-components';
import {
  MsSQLDatabaseResponse,
  CreateMsSQLRequestBody,
} from '@lego/plugin-baseplate-hosting-platform-mssql-common';

export class MsSQLApiClient implements MsSQLApi {
  private readonly apiUtils: ApiUtils;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.apiUtils = new ApiUtils(
      options.discoveryApi,
      options.fetchApi,
      'mssql',
    );
  }

  public async getAllMsSQLDatabase(
    productId: string,
  ): Promise<MsSQLDatabaseResponse> {
    return await this.apiUtils.get<MsSQLDatabaseResponse>(`mssql/${productId}`);
  }

  public async createMsSQL(
    requestBody: CreateMsSQLRequestBody,
  ): Promise<unknown> {
    return await this.apiUtils.post<CreateMsSQLRequestBody>(
      'sql-server',
      requestBody,
    );
  }
}
