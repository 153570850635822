import React from 'react';
import {
  FormStepTitle,
  SummaryTable,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../utils/types';
import { getCloudAccountName } from '../utils/getCloudAccountName';
import { ErrorMessage } from 'formik';
import { Alert } from '@mui/material';
import { currencyByVendor } from '../utils/currencyByVendor';

const ErrorAlert = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {msg => (
      <Box mt={2}>
        <Alert severity="error">{msg}</Alert>
      </Box>
    )}
  </ErrorMessage>
);

export const StepSummary = ({ values, productId }: StepProps) => {
  const summaryValues = [
    { label: 'Vendor', value: values.vendor || 'Missing field - Go to step 1' },
    {
      label: 'Account name',
      value: getCloudAccountName(
        String(values.name) || '{ Missing field }',
        String(values.environment) || '{ Missing field }',
      ),
    },
    {
      label: 'Application',
      value: `${values.applicationId} - ${values.applicationName}`,
    },
    {
      label: 'Purpose',
      value: values.purpose || 'Missing field - Go to step 2',
    },
    { label: 'Owner', value: values.owner || 'Missing field - Go to step 2' },
    {
      label: 'Backup contact',
      value: values.backupContact || 'Missing field - Go to step 2',
    },
    {
      label: 'MS Teams Channel Notification',
      value: values.notificationEndpoint || 'Missing field - Go to step 2',
    },
    {
      label: 'Monthly budget',
      value:
        `${values.monthlyBudget} ${currencyByVendor(values.vendor)}` ||
        'Missing field - Go to step 3',
    },
    ...(values.vendor !== 'Azure'
      ? [
          {
            label: 'Budget alerts',
            value:
              `At ${values.budgetAlertThreshold1}% (${values.budgetAlertThreshold1Type}), ${values.budgetAlertThreshold2}% (${values.budgetAlertThreshold2Type}) and ${values.budgetAlertThreshold3}% (${values.budgetAlertThreshold3Type})` ||
              'Missing field - Go to step 3',
          },
        ]
      : []),
    {
      label: 'Budget approver',
      value: values.budgetApprover || 'Missing field - Go to step 3',
    },
  ];

  return (
    <Grid>
      <FormStepTitle
        formTitle={`Create Cloud Account for ${productId}`}
        stepTitle="Summary"
        step={4}
      />
      <Box mt={1} mb={1}>
        <Typography variant="body1">Summary goes here</Typography>
        <SummaryTable values={summaryValues} />
      </Box>
      {[
        'vendor',
        'name',
        'environment',
        'application',
        'purpose',
        'owner',
        'backupContact',
        'notificationEndpoint',
        'monthlyBudget',
        'budgetApprover',
      ].map(field => (
        <ErrorAlert key={field} name={field} />
      ))}
    </Grid>
  );
};
