import { Entity } from '@backstage/catalog-model';
import { AlphaEntity, EntityStatusItem } from '@backstage/catalog-model/alpha';
import {
  ENTITY_IS_DUPLICATED,
  ENTITY_STATUS_AMMA_API_PROCESSING,
  GROUP_UNDEFINED_REF,
  LABEL_LIFECYCLE,
} from '../constants';
import { ToolEntity } from '../kinds';
import { CommunityEntity } from '@lego/plugin-baseplate-communities';

export const isDuplicatedEntity = (entity: Entity): boolean => {
  return !!entity.metadata.annotations?.[ENTITY_IS_DUPLICATED];
};

export const getAmmaProcessingErrors = (entity: Entity): EntityStatusItem[] => {
  const items = (entity as AlphaEntity).status?.items ?? [];
  return items.filter(item => item.type === ENTITY_STATUS_AMMA_API_PROCESSING);
};

export const hasAmmaProcessingErrors = (entity: Entity): boolean => {
  return getAmmaProcessingErrors(entity).length > 0;
};

export const isMissingLifecycle = (entity: Entity): boolean =>
  entity.metadata.labels?.[LABEL_LIFECYCLE] === 'undefined';

export const isMissingOwner = (entity: Entity): boolean =>
  entity.spec === undefined || entity.spec?.owner === GROUP_UNDEFINED_REF;

export const isProduct = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'product';

export const isApplication = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'application';

export const isGroup = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'group';

export const isAPI = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'api';

export const isSystem = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'system';

export const isComponent = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'component';

export const isDomain = (entity: Entity): boolean =>
  isGroup(entity) &&
  (entity.spec?.type as string).toLocaleLowerCase('en-US') === 'domain';

export const isSubDomain = (entity: Entity): boolean =>
  isGroup(entity) &&
  (entity.spec?.type as string).toLocaleLowerCase('en-US') === 'sub-domain';

export const isOrganization = (entity: Entity): boolean =>
  isGroup(entity) &&
  (entity.spec?.type as string).toLocaleLowerCase('en-US') === 'organization';

export const isTool = (entity: Entity): entity is ToolEntity =>
  entity.kind.toLocaleLowerCase('en-US') === 'tool';

export const isCommunity = (entity: Entity): entity is CommunityEntity =>
  entity.kind.toLocaleLowerCase('en-US') === 'community';

export const isService = (entity: Entity): boolean =>
  entity.kind.toLocaleLowerCase('en-US') === 'service';

type FormatEntityNameOptions = {
  maxLength?: number;
  separator?: string;
};

const concatAndShorten = (
  sanitized: string[],
  maxLength: number,
  separator: string,
): string => {
  const [first, ...rest] = sanitized;
  let output = first;

  if (output.length > maxLength) {
    return first.slice(0, maxLength);
  }

  for (const item of rest) {
    const result = `${output}${separator}${item}`;
    if (result.length > maxLength) {
      break;
    }
    output = result;
  }
  return output;
};

/**
 * Entity name has some [limitations](https://github.com/backstage/backstage/blob/master/docs/architecture-decisions/adr002-default-catalog-file-format.md#name), namely:
 * - Strings of length at least 1, and at most 63
 * - Must consist of sequences of [a-z0-9A-Z] possibly separated by one of [-_.]
 */

export const formatEntityName = (
  input: string,
  { maxLength = 100, separator = '-' }: FormatEntityNameOptions = {},
): string => {
  const sanitized = input
    .split(/ /)
    // eslint-disable-next-line no-useless-escape
    .map(it => it.replace(/[\\/]/g, '-').replace(/[^a-z0-9A-Z\-_\.]/g, ''))
    .filter(it => it); // filters out '' array parts when split(/ /) is applied on strings having more than 1 subsequent space e.g. "  "
  const concatenatedWithPotentialSeparatorConsecutive = concatAndShorten(
    sanitized,
    maxLength,
    separator,
  );
  const concatenatedWithoutPotentialSeparatorConsecutive =
    concatenatedWithPotentialSeparatorConsecutive.replace(/-{2,}/g, '-');
  const leadingTrailingSeparatorsRemoved =
    concatenatedWithoutPotentialSeparatorConsecutive.replace(
      /(^[-_.]+)|([-_.]+$)/gm,
      '',
    );
  return leadingTrailingSeparatorsRemoved;
};

/**
 * Entity namespace (e.g. AMMA workspace) has some [limitations](https://github.com/backstage/backstage/blob/master/docs/architecture-decisions/adr002-default-catalog-file-format.md#namespace), namely:
 * - Strings of length at least 1, and at most 63 (default is overwritten to 100 in catalog.ts)
 * - Must be a string that is sequences of [a-z0-9] separated by [-]
 */

export const formatEntityNamespace = (
  input: string,
  { maxLength = 100, separator = '-' }: FormatEntityNameOptions = {},
): string => {
  const sanitized = input
    .split(/ /)
    .map(it =>
      it
        // eslint-disable-next-line no-useless-escape
        .replace(/[\\/\._]/g, '-')
        // eslint-disable-next-line no-useless-escape
        .replace(/[^a-z0-9A-Z\-]/g, '')
        .toLowerCase(),
    )
    .filter(it => it); // filters out '' array parts when split(/ /) is applied on strings having more than 1 subsequent space e.g. "  "
  const concatenatedWithPotentialSeparatorConsecutive = concatAndShorten(
    sanitized,
    maxLength,
    separator,
  );
  const concatenatedWithoutPotentialSeparatorConsecutive =
    concatenatedWithPotentialSeparatorConsecutive.replace(/-{2,}/g, '-');
  const leadingTrailingSeparatorsRemoved =
    concatenatedWithoutPotentialSeparatorConsecutive.replace(
      /(^[-]+)|([-]+$)/gm,
      '',
    );
  return leadingTrailingSeparatorsRemoved;
};

export const getNameFromEmail = (input: string | undefined | null): string => {
  return input?.split('@')[0] ?? 'undefined-email';
};
