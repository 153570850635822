import { ResponseErrorPanel } from '@backstage/core-components';
import { Banner } from '@lego/plugin-baseplate-admin-common';
import { Button, Table } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { useBanner } from '../../hooks';
import { getColumns } from './columns';
import { headers } from './headers';

export const BannerTable = ({
  handleOpenEdit,
  handleOpenCreate,
}: {
  handleOpenEdit: (banner: Banner) => void;
  handleOpenCreate: () => void;
}) => {
  const { banners, handleDeletingBanner, loading, error } = useBanner();

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const deleteBanner = async (id: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this banner?')) {
      await handleDeletingBanner(id);
    }
  };

  return (
    <Table<Banner>
      loading={loading}
      title="Active Banners"
      headers={headers}
      data={getColumns(banners)}
      emptyState={
        <div style={{ maxWidth: 550 }}>
          <Typography variant="subtitle2" color="secondary">
            No banners found. Create one to display it on the home page.
          </Typography>
          <Typography variant="body1" color="secondary">
            Banners are a great way to communicate important information to our
            users.
          </Typography>
        </div>
      }
      showTableActionsForNoData
      actions={
        <Button
          onClick={handleOpenCreate}
          startIcon={<AddIcon />}
          variant="primary"
        >
          Create
        </Button>
      }
      rowMenuActions={[
        {
          label: 'Edit',
          icon: 'pen',
          onClick: row => {
            if (row.data) {
              handleOpenEdit(row.data);
            }
          },
        },
        {
          label: 'Delete',
          icon: 'trash',
          onClick: row => {
            if (row.data) {
              void deleteBanner(row.data.id);
            }
          },
        },
      ]}
    />
  );
};
