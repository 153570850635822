import {
  appThemeApiRef,
  featureFlagsApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Banner } from '@lego/plugin-baseplate-admin-common';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import { AdminApiRef } from '../../api';
import { DismissableBanner } from './DismissableBanner';

export const Banners = () => {
  const adminApi = useApi(AdminApiRef);
  const appThemeApi = useApi(appThemeApiRef);
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const theme = appThemeApi.getActiveThemeId();
  const { pathname } = useLocation();
  const [filteredBanners, setFilteredBanners] = useState<Banner[]>([]);

  const filterFeatureFlags = (banner: Banner) => {
    return !banner.featureFlag || featureFlagsApi.isActive(banner.featureFlag);
  };

  const filterTheme = (banner: Banner) => {
    return banner.theme === null || banner.theme === theme;
  };

  const isValidRegex = (pattern: string) => {
    try {
      const _ = new RegExp(pattern);
      return true;
    } catch {
      return false;
    }
  };

  const filterPath = (banner: Banner) => {
    if (!banner.pages || banner.pages.length === 0) {
      return true;
    }
    return banner.pages.some(page => {
      try {
        const url = new URL(page);
        return url.pathname === pathname;
      } catch {
        if (isValidRegex(page)) {
          const regex = new RegExp(page);
          return regex.test(pathname);
        }
        return page === pathname;
      }
    });
  };

  const { value: banners, loading } = useAsync(async () => {
    return await adminApi.getBanners();
  });

  useEffect(() => {
    if (banners) {
      const filtered = banners
        .filter(filterTheme)
        .filter(filterPath)
        .filter(filterFeatureFlags);
      setFilteredBanners(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banners, pathname, theme, featureFlagsApi]);

  if (loading || !banners) {
    return null;
  }

  return (
    <>
      {filteredBanners.map((banner, index) => (
        <div style={index === 0 ? { marginTop: '4rem' } : {}} key={index}>
          <DismissableBanner banner={banner} id={banner.id} />
        </div>
      ))}
    </>
  );
};
