import { createApiRef } from '@backstage/core-plugin-api';
import { HostingPlatformDataResponse } from '@lego/plugin-baseplate-hosting-platform-common';

export const HostingPlatformApiRef = createApiRef<HostingPlatformApi>({
  id: 'plugin.hosting.platform',
});

export interface HostingPlatformApi {
  getAllHostingPlatformData(
    productId: string,
  ): Promise<HostingPlatformDataResponse>;
}
