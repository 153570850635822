import React, { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore The library doesn't have fix yet.
import quillEmoji from 'quill-emoji';
import { CommunityV2 } from '@lego/plugin-baseplate-communities-common';

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'emoji'],
  ],
  'emoji-toolbar': true,
  'emoji-textarea': false,
  'emoji-shortname': true,
};

export const FormQuill = ({
  formValues,
  handleInputChange,
}: {
  formValues: CommunityV2;
  handleInputChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | {
          target: {
            name: string;
            value: string;
          };
        },
  ) => void;
}) => {
  const [QuillLoaded, setQuillLoaded] = React.useState(false);

  const { EmojiBlot, ToolbarEmoji, TextAreaEmoji, ShortNameEmoji } = quillEmoji;

  useEffect(() => {
    if (Quill && EmojiBlot && ToolbarEmoji && TextAreaEmoji && ShortNameEmoji) {
      // Register Quill Emoji
      Quill.register(
        {
          'formats/emoji': EmojiBlot,
          'modules/emoji-toolbar': ToolbarEmoji,
          'modules/emoji-textarea': TextAreaEmoji,
          'modules/emoji-shortname': ShortNameEmoji,
        },
        true,
      );
      setQuillLoaded(true);
    }
  }, [EmojiBlot, ToolbarEmoji, TextAreaEmoji, ShortNameEmoji]);

  if (!QuillLoaded) {
    return null;
  }

  return (
    <ReactQuill
      theme="snow"
      modules={quillModules}
      value={formValues.about}
      onChange={e =>
        handleInputChange({
          target: { name: 'about', value: e },
        })
      }
    />
  );
};
