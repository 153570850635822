import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { FC } from 'react';
import { apiDeploymentLogsRouteRef, rootRouteRef } from './routes';

export const apiMetricsPlugin = createPlugin({
  id: 'api-metrics',
  routes: {
    root: rootRouteRef,
  },
});

export const ApiMetricsPage: FC = apiMetricsPlugin.provide(
  createRoutableExtension({
    name: 'ApiMetricsPage',
    component: () =>
      import('./components/ApiMetricsComponent').then(
        m => m.ApiMetricsComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const configApiLogsPlugin = createPlugin({
  id: 'api-deployment-logs',
  routes: {
    root: apiDeploymentLogsRouteRef,
  },
});

export const ApiDeploymentLogsPage: FC = configApiLogsPlugin.provide(
  createRoutableExtension({
    name: 'ApiDeploymentLogsPage',
    component: () =>
      import('./components/DeploymentLogsComponent').then(
        m => m.DeploymentLogsComponent,
      ),
    mountPoint: apiDeploymentLogsRouteRef,
  }),
);
