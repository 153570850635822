import * as Yup from 'yup';
import { environments } from './types';

const stepVendorSchema = Yup.object().shape({
  vendor: Yup.string().required('Vendor is required'),
});

const purposeMinMaxErrorMessage =
  'The description must be at least 30 characters long but not longer than 300 characters.';

const getEmailYup = (fieldName: string) =>
  Yup.string().required(`${fieldName} is required`);
// TODO: Check whether validation on EntityDropDown is necessary. Do not think so.
//  .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(lego\.com)$/i, `All person fields must contain a @lego.com Email.`);

const stepInformationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(20, 'Maximum 20 characters'),
  environment: Yup.string()
    .required('Environment is required')
    .oneOf(environments.map(x => x.value)),
  applicationId: Yup.string().required('Application is required'),
  // TODO: Check whether validation on EntityDropDown is necessary. Do not think so.
  //  .matches(
  //    /^APP-[0-9]{1,5} - .+$/,
  //    `Your application must contain the ID and the name. Please use the following format 'APP-01234 - Name of application'.`,
  //  ),
  purpose: Yup.string()
    .required('Purpose is required')
    .min(30, purposeMinMaxErrorMessage)
    .max(300, purposeMinMaxErrorMessage),
  owner: getEmailYup('Owner'),
  backupContact: getEmailYup('Backup owner'),
  notificationEndpoint: Yup.string()
    .required('Notification endpoint is required')
    .matches(
      /^[^~#%&*{}+/:<>?|'".]+\s-\s.+\s<[a-z0-9]{8}\.(o365\.corp\.LEGO|legogroup\.onmicrosoft)\.com@emea\.teams\.ms>$/,
      "The provided value doesn't follow the required format. Please, copy paste the value from MS Teams.",
    ),
});

const stepBudgetSchema = Yup.object().shape({
  monthlyBudget: Yup.number().required('Monthly budget is required'),
  budgetApprover: getEmailYup('Budget approver'),
  // TODO Add validation for budget alerts
});

// const stepSummarySchema = stepVendorSchema
//   .concat(stepInformationSchema)
//   .concat(stepBudgetSchema);

export const cloudFormValidationSchema = (step: number) => {
  switch (step) {
    case 1:
      return stepVendorSchema;
    case 2:
      return stepInformationSchema;
    case 3:
      return stepBudgetSchema;
    case 4:
    default:
      return null;
  }
};
