import React, { useMemo } from 'react';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  Card,
  LineChart,
  Table,
  TableRowType,
} from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';
import { ApiType } from '../../types';
import { ChartColorSchemes } from '@lego/plugin-baseplate-core-components/src/components/chart/types';

export interface UsageCardProps {
  entity: Entity;
}

const months = [
  '2024-01-01',
  '2024-02-01',
  '2024-03-01',
  '2024-04-01',
  '2024-05-01',
  '2024-06-01',
  '2024-07-01',
  '2024-08-01',
  '2024-09-01',
  '2024-10-01',
  '2024-11-01',
  '2024-12-01',
];

export function UsageCard({ entity }: UsageCardProps): ReactElement {
  const type = entity.spec?.type as ApiType;
  const consumers =
    useRelatedEntities(entity, {
      type: RELATION_API_CONSUMED_BY,
    }).entities?.slice(0, 5) ?? [];

  const data = useMemo(
    () => [
      {
        id: 'Req/month',
        data: months.map((month, i) => ({
          x: month,
          y: i * Math.round(Math.cos(i) + 1.5) * 1000,
        })),
      },
    ],
    [],
  );

  return (
    <Card title="Usage">
      <LineChart
        summary={{
          value: 1412,
          unit: 'requests last 30 days (dummy data)',
        }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'month',
        }}
        axisBottom={{
          format: '%b',
          tickValues: 'every month',
        }}
        colorScheme={
          {
            openapi: 'sequential-green',
            asyncapi: 'sequential-blue',
            graphql: 'sequential-purple',
          }[type] as ChartColorSchemes
        }
        enableArea
        xFormat="time:%y-%m-%d"
        data={data}
        enableGridX={false}
        height={200}
      />
      <Table
        title=""
        headers={[
          {
            label: 'Name',
            key: 'name',
          },
          {
            label: 'Product',
            key: 'product',
          },
          {
            label: 'Requests last 30 days',
            key: 'usage',
          },
        ]}
        data={consumers.map(
          (consumer, i): TableRowType => ({
            id: consumer.metadata.name,
            columns: {
              name: {
                value: stringifyEntityRef(consumer),
                type: 'entity',
              },
              product: {
                value: 'PRO-227',
                type: 'entity',
              },
              usage: {
                value: i * Math.round(Math.sin(i) + 1.5) * 100,
                type: 'number',
              },
            },
          }),
        )}
      />
    </Card>
  );
}
