import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
  useAnalytics,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { reactQueryEnableCacheOptions } from './api.const';

const useCloudFetch = <JSONResponse>(
  path: `/${string}`,
  init: RequestInit = {},
  includeRequestingUser: boolean,
  mapFn?: (x: any) => JSONResponse,
) => {
  const discoveryApi = useApi(discoveryApiRef);
  const { fetch } = useApi(fetchApiRef);
  const identityApi = useApi(identityApiRef);

  const getRequestingUserHeader = async () =>
    includeRequestingUser
      ? {
          'x-requesting-user': await identityApi
            .getProfileInfo()
            .then(
              data =>
                data.email ??
                Promise.reject(new Error(`E5647: Not able to get email`)),
            ),
        }
      : Promise.resolve({});

  return () =>
    Promise.all([discoveryApi.getBaseUrl('cloud'), getRequestingUserHeader()])
      .then(([url, requestingUseerHeader]) =>
        fetch(`${url}${path}`, {
          ...init,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            ...init?.headers,
            ...requestingUseerHeader,
          },
        }),
      )
      .then(resp =>
        resp.ok
          ? resp
          : resp.json().then(
              json =>
                Promise.reject({
                  message: resp.statusText,
                  statusCode: resp.status,
                  notJSON: false,
                  ...json,
                }),
              e =>
                Promise.reject({
                  message: resp.statusText,
                  statusCode: resp.status,
                  notJSON: true,
                  fetchError: e?.message,
                }),
            ),
      )
      .then(resp =>
        resp
          .json()
          .then(x => (mapFn ? mapFn(x) : (x as unknown as JSONResponse))),
      )
      .catch(e =>
        Promise.reject({
          message: e?.message ?? 'Unknown error. Try again later',
          ...e,
        }),
      );
};

export const useCloudQuery = <JSONResponse>(
  path: `/${string}`,
  mapFn?: (x: any) => JSONResponse,
  analyticsKey?: string,
  cacheKey?: string[],
) => {
  const fetcher = useCloudFetch<JSONResponse>(path, {}, true, mapFn);
  const analytics = useAnalytics();

  const givenCacheKey = cacheKey ?? path.replace(/^\//, '').split('/');

  const query = useQuery<
    JSONResponse,
    {
      message: string;
      statusCode?: number;
    }
  >(givenCacheKey, fetcher, {
    ...reactQueryEnableCacheOptions,
    onSuccess: () => {
      if (analyticsKey) {
        analytics.captureEvent('api-call', analyticsKey);
      }
    },
    onError: () => {
      if (analyticsKey) {
        analytics.captureEvent('api-error', analyticsKey);
      }
    },
  });

  return {
    ...query,
    cacheKey,
  };
};

export const useCloudMutation = <
  JSONResponse,
  ERRORResponse = {
    message: string;
    statusCode?: number;
    notJSON?: boolean;
  },
>(
  path: `/${string}`,
  init?: RequestInit,
  invalidateCacheKeys: string[][] = [],
  analyticsKey: string = path.replace(/\//gi, '-'),
  callback?: UseMutateFunction,
) => {
  const fetcher = useCloudFetch<JSONResponse>(
    path,
    {
      ...init,
      headers: {
        ...init?.headers,
      },
    },
    true,
  );
  const analytics = useAnalytics();
  const queryClient = useQueryClient();

  const mutation = useMutation<JSONResponse, ERRORResponse>(fetcher, {
    onSuccess: async () => {
      if (callback) {
        callback();
      }
      if (analyticsKey) {
        analytics.captureEvent('api-call', analyticsKey);
      }
      await Promise.all(
        (invalidateCacheKeys ?? []).map(cacheKey =>
          queryClient.invalidateQueries(cacheKey),
        ),
      );
    },
    onError: () => {
      if (analyticsKey) {
        analytics.captureEvent('api-error', analyticsKey);
      }
    },
  });

  return mutation;
};
