import { useAnalytics, useApiHolder } from '@backstage/core-plugin-api';
import {
  FieldExtensionOptions,
  FormProps,
  LayoutOptions,
  ReviewStepProps,
  TemplateParameterSchema,
} from '@backstage/plugin-scaffolder-react';
import { JsonValue } from '@backstage/types';
import {
  FormStep,
  FormStepTitle,
} from '@lego/plugin-baseplate-core-components';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { type IChangeEvent } from '@rjsf/core';
import { ErrorSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, {
  ComponentType,
  useCallback,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

import {
  Form,
  FormValidation,
  ReviewStateProps,
  createAsyncValidators,
  useFormDataFromQuery,
  useTemplateSchema,
} from '@backstage/plugin-scaffolder-react/alpha';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { capitalize } from 'lodash';

import { ReviewStepComponent } from './ReviewStepComponent';
import { useTransformSchemaToProps } from './hooks';
import BaseInputTemplate from './templates/BaseInputTemplate';
import { ErrorListTemplate } from './templates/ErrorListTemplate';
import { hasErrors } from './utils';
import SelectWidget from './widgets/SelectWidget';
import Markdown from 'markdown-to-jsx';

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
  },
  formWrapper: {
    padding: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
}));

/**
 * The Props for {@link Stepper} component
 * @alpha
 */
export type StepperProps = {
  manifest: TemplateParameterSchema;
  extensions: FieldExtensionOptions<any, any>[];
  templateName?: string;
  formProps?: FormProps;
  initialState?: Record<string, JsonValue>;
  onCreate: (values: Record<string, JsonValue>) => Promise<void>;
  components?: {
    ReviewStepComponent?: ComponentType<ReviewStepProps>;
    ReviewStateComponent?: (props: ReviewStateProps) => JSX.Element;
    backButtonText?: ReactNode;
    createButtonText?: ReactNode;
    reviewButtonText?: ReactNode;
  };
  layouts?: LayoutOptions[];
  showErrorList?: false | 'top' | 'bottom';
};

/**
 * The `Stepper` component is the Wizard that is rendered when a user selects a template
 * @alpha
 */
export const Stepper = (stepperProps: StepperProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { layouts = [], components = {}, ...props } = stepperProps;
  const { backButtonText = 'Back', reviewButtonText = 'Summary' } = components;
  const analytics = useAnalytics();
  const { presentation, steps } = useTemplateSchema(props.manifest);
  const apiHolder = useApiHolder();
  const [activeStep, setActiveStep] = useState(0);
  const [isValidating, setIsValidating] = useState(false);
  const [formState, setFormState] = useFormDataFromQuery(props.initialState);
  const [errors, setErrors] = useState<undefined | FormValidation>();
  const styles = useStyles();

  const extensions = useMemo(() => {
    return Object.fromEntries(
      props.extensions.map(({ name, component }) => [name, component]),
    );
  }, [props.extensions]);

  const fields = useMemo(() => ({ ...extensions }), [extensions]);

  const validators = useMemo(() => {
    return Object.fromEntries(
      props.extensions.map(({ name, validation }) => [name, validation]),
    );
  }, [props.extensions]);

  const validation = useMemo(() => {
    return createAsyncValidators(steps[activeStep]?.mergedSchema, validators, {
      apiHolder,
    });
  }, [steps, activeStep, validators, apiHolder]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = useCallback(
    (e: IChangeEvent) =>
      setFormState(current => ({ ...current, ...e.formData })),
    [setFormState],
  );

  const handleCreate = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    props.onCreate(formState);
  }, [props, formState]);

  const currentStep = useTransformSchemaToProps(steps[activeStep], { layouts });

  const handleNext = async ({
    formData = {},
  }: {
    formData?: Record<string, JsonValue>;
  }) => {
    // The validation should never throw, as the validators are wrapped in a try/catch.
    // This makes it fine to set and unset state without try/catch.
    setErrors(undefined);
    setIsValidating(true);

    const returnedValidation = await validation(formData);

    setIsValidating(false);

    if (hasErrors(returnedValidation)) {
      setErrors(returnedValidation);
    } else {
      setErrors(undefined);
      setActiveStep(prevActiveStep => {
        const stepNum = prevActiveStep + 1;
        analytics.captureEvent('click', `Next Step (${stepNum})`);
        return stepNum;
      });
    }
    setFormState(current => ({ ...current, ...formData }));
  };

  const backLabel =
    presentation?.buttonLabels?.backButtonText ?? backButtonText;

  const reviewLabel =
    presentation?.buttonLabels?.reviewButtonText ?? reviewButtonText;

  return (
    <>
      {isValidating && <LinearProgress variant="indeterminate" />}
      <FormStep.Wrapper>
        {steps.map((step, index) => (
          <FormStep.Item
            aria-label={`Step ${index + 1}`}
            key={index}
            label={step.title}
            stepNumber={index + 1}
            active={activeStep === index}
            completed={activeStep > index}
            disabled={activeStep < index}
            onClick={() => {
              setActiveStep(index);
            }}
          />
        ))}
        <FormStep.Item
          aria-label="Review-step"
          key={steps.length + 1}
          label="Summary"
          active={activeStep === steps.length}
          stepNumber={steps.length + 1}
        />
      </FormStep.Wrapper>

      <div className={styles.formWrapper}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {activeStep < steps.length ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={isSmallScreen ? '100%' : '740px'}
            mx="auto"
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
                padding: '16px',
              }}
            >
              <FormStepTitle
                formTitle={props.manifest.title}
                stepTitle={currentStep.title}
                step={activeStep + 1}
              />
            </Box>

            <Form
              validator={validator}
              extraErrors={errors as unknown as ErrorSchema}
              formData={formState}
              formContext={{ formData: formState }}
              schema={currentStep.schema}
              uiSchema={currentStep.uiSchema}
              widgets={{
                SelectWidget,
              }}
              templates={{
                DescriptionFieldTemplate: ({ description, id }) => {
                  if (description) {
                    if (typeof description === 'string') {
                      return (
                        <div style={{ paddingLeft: 16 }}>
                          <Markdown
                            children={description}
                            options={{
                              overrides: {
                                p: {
                                  component: Typography,
                                  props: {
                                    variant: 'body1',
                                    padding: 0,
                                    margin: 0,
                                  },
                                },
                                ol: {
                                  component: Typography,
                                  props: {
                                    variant: 'body1',
                                    style: {
                                      padding: 0,
                                      margin: 0,
                                    },
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      );
                    }

                    return (
                      <Typography
                        id={id}
                        variant="subtitle2"
                        style={{ marginTop: '5px' }}
                      >
                        {description}
                      </Typography>
                    );
                  }

                  return null;
                },
                BaseInputTemplate,
                ErrorListTemplate, // eslint-disable-next-line @typescript-eslint/no-shadow
                FieldErrorTemplate: ({ errors }) => {
                  if (!errors) return null;
                  return (
                    <div style={{ display: 'grid', gridGap: '20px' }}>
                      {errors.map((error, index) => (
                        <Typography key={index} color="error">
                          {/* eslint-disable-next-line @typescript-eslint/no-base-to-string */}
                          {capitalize(error.toString().replace(/_/g, ' '))}
                        </Typography>
                      ))}
                    </div>
                  );
                },
              }}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleNext}
              fields={fields}
              transformErrors={e =>
                e.map(error => {
                  if (error.name === 'required') {
                    error.message = `${capitalize(error.property)} is required`;
                  }
                  return error;
                })
              }
              className={styles.form}
              showErrorList={props.showErrorList ?? false}
              onChange={handleChange}
              experimental_defaultFormStateBehavior={{
                allOf: 'populateDefaults',
              }}
              {...(props.formProps ?? {})}
            >
              <div className={styles.footer}>
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  className={styles.backButton}
                  disabled={activeStep < 1 || isValidating}
                >
                  {backLabel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isValidating}
                >
                  {activeStep === steps.length - 1 ? reviewLabel : 'Next'}
                </Button>
              </div>
            </Form>
          </Box>
        ) : (
          <ReviewStepComponent
            disableButtons={isValidating}
            formData={formState}
            handleBack={handleBack}
            handleReset={() => {}}
            steps={steps}
            handleCreate={handleCreate}
            manifest={props.manifest}
          />
        )}
      </div>
    </>
  );
};
