import { makeStyles } from '@material-ui/core';

export const useManageCommunityStyles = makeStyles<any>(theme => ({
  mngCommContainer: {
    width: '100%',
  },
  mngCommHeadContainer: {
    justifyContent: 'space-between',
    padding: '24px 4rem',
    paddingBottom: 0,
    background: theme.semantic.background.neutral[1],
    position: 'relative',
    flexDirection: 'row',
    width: '100%',
    margin: '0',
    zIndex: 99,
    borderBottom: `1px solid ${theme.semantic.border.default}`,

    '& > nav': {
      marginBottom: 5,
    },
  },
  loadingStyle: {
    padding: '20px',
  },
  mngCommFormContent: {
    background: theme.semantic.background.neutral[1],
    position: 'relative',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    padding: '24px 4rem',
    margin: '0 auto',
  },

  mngCommStepHead: {
    marginBottom: 20,
  },
  mngCommStepCaption: {
    textTransform: 'uppercase',
    color: theme.semantic.text.secondary,
  },

  mngCommFormPropGithubRepoOption: {
    '& > div > div': {
      marginBottom: 10,
      '&:first-of-type': {
        opacity: 0.5,
      },
    },
  },

  mngCommFormStepsContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    minHeight: 550,
  },

  mngCommFormBtnGrp: {
    maxWidth: 1000,
    margin: '0 auto',
    marginTop: 30,
    '& > button': { marginRight: 10 },
  },

  mngCommFormPropPlatform: {
    '& > div > div > div': {
      marginBottom: 10,
    },
  },

  mngCommCaption: {
    padding: '10px 0',
  },

  mngCommFormGithubOptionsInfo: {
    padding: '15px 0 5px 0',

    '& > div': {
      color: theme.semantic.text.primary,
      borderColor: theme.semantic.border.default,
      background: theme.semantic.background.neutral[3],
      '& > .MuiTypography-root': {
        color: theme.semantic.text.primary,
      },
    },
  },

  mngCommFormSection: {
    marginTop: 30,
    '& > .v5-MuiAutocomplete-root > .MuiFormControl-root > .v5-MuiFormControl-root > .v5-MuiInputBase-root':
      {
        height: 40,
        padding: '0px 12px',
      },
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },

  mngCommFormPropAbout: {
    '& > .quill': {
      '& > .ql-toolbar': {
        fontFamily: theme.typography.fontFamily,
        borderRadius: `${theme.primitive.borderRadius.default}px ${theme.primitive.borderRadius.default}px  0px 0px`,
      },
      '& >.ql-container': {
        borderRadius: ` 0px  0px ${theme.primitive.borderRadius.default}px ${theme.primitive.borderRadius.default}px`,
        '& > .ql-editor ': {
          fontFamily: theme.typography.fontFamily,
          minHeight: 300,
        },
      },
    },
  },

  chkControlLabel: {
    '& > .v5-MuiCheckbox-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& > svg': {
        width: 30,
        height: 30,
        color: theme.semantic.border.default,
      },
      '&.Mui-checked': {
        '&  > svg': {
          color: theme.component.button.primary.background.default,
        },
      },
    },
  },
  errorText: {
    color: theme.semantic.text.error,
  },
  mngPlatformsError: {
    '& > div > div > label': {
      borderColor: theme.semantic.border.error,
      boxShadow: `0 0 0 2px ${theme.semantic.border.error}80`,
    },
  },
  disabledControl: {
    opacity: 0.5,
  },
  helperText: {
    marginLeft: 10,
    marginTop: 5,
    color: theme.semantic.text.secondary,
    '& > span': {
      fontWeight: 'bold',
      color: theme.semantic.text.primary,
    },
  },
}));
