import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { HostingPlatformApi } from './HostingPlatformApi';
import { ApiUtils } from '@lego/plugin-baseplate-core-components';
import { HostingPlatformDataResponse } from '@lego/plugin-baseplate-hosting-platform-common';

export class HostingPlatformApiClient implements HostingPlatformApi {
  private readonly apiUtils: ApiUtils;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.apiUtils = new ApiUtils(
      options.discoveryApi,
      options.fetchApi,
      'hosting-platform',
    );
  }

  public async getAllHostingPlatformData(
    productId: string,
  ): Promise<HostingPlatformDataResponse> {
    return await this.apiUtils.get<HostingPlatformDataResponse>(
      `services/instances/${productId}`,
    );
  }
}
