import { semantic } from '../../../design-tokens';

export const getChartTheme = (mode: 'light' | 'dark') => ({
  background: semantic[mode].background.neutral[1],
  text: {
    fill: semantic[mode].text.primary,
    fontFamily: 'inherit',
  },
  axis: {
    domain: {
      line: {
        stroke: semantic[mode].border.default,
      },
    },
    legend: {
      text: {
        fill: semantic[mode].text.primary,
      },
    },
    ticks: {
      line: {
        stroke: semantic[mode].border.default,
      },
      text: {
        fill: semantic[mode].text.primary,
        fontSize: 12,
      },
    },
  },
  grid: {
    line: {
      stroke: semantic[mode].border.default,
    },
  },
  legends: {
    title: {
      text: {
        fill: semantic[mode].text.primary,
      },
    },
    text: {
      fill: semantic[mode].text.primary,
    },
    ticks: {
      text: {
        fill: semantic[mode].text.primary,
      },
    },
  },
  crosshair: {
    line: {
      stroke: semantic[mode].shape.neutral,
    },
  },
  annotations: {
    text: {
      fill: semantic[mode].text.primary,
    },
    link: {
      stroke: semantic[mode].text.primary,
    },
    outline: {
      stroke: semantic[mode].text.primary,
    },
    symbol: {
      fill: semantic[mode].text.primary,
    },
  },
  tooltip: {
    container: {
      background: semantic[mode].background.neutral[2],
      color: semantic[mode].text.primary,
    },
  },
});
