import { useAnalytics, useApi } from '@backstage/core-plugin-api';
import { Button, showToast } from '@lego/plugin-baseplate-core-components';
import { Grid, Modal, Typography } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import React, { useState } from 'react';
import { communitiesV2ApiRef } from '../../apiV2';
import { useCommunity } from '../../hooks';
import { useJoinCommunityStyles } from '../Community/JoinCommunity/JoinCommunity.styles';

export const JoinButton = () => {
  const analytics = useAnalytics();
  const communitiesV2Api = useApi(communitiesV2ApiRef);
  const styles = useJoinCommunityStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    entity,
    backstageIdentity,
    isUserCommunityMember,
    membershipUpdateLoading,
    setMembershipUpdateLoading,
    isUserMaintainer,
    setIsLeaveDialogOpen,
  } = useCommunity();

  const joinCommunity = async () => {
    try {
      setMembershipUpdateLoading(true);
      analytics.captureEvent('community_engagement', 'community_join');
      if (entity && backstageIdentity) {
        const communityWithNewMember = await communitiesV2Api.joinCommunity(
          entity.metadata.dbId,
          backstageIdentity?.userEntityRef,
        );
        if (communityWithNewMember.id) {
          void showToast({
            type: 'success',
            message: 'Successfully joined community!',
          });
        }
      } else {
        throw new Error(
          'Something went wrong while reading user data, please try again later.',
        );
      }
    } catch (error) {
      void showToast({
        type: 'error',
        message: (error as Error).message,
      });
    } finally {
      setMembershipUpdateLoading(false);
    }
  };

  const handleJoinClick = async () => {
    if (entity?.spec.teamsChannelLink) {
      setIsModalOpen(true);
    } else {
      await joinCommunity();
    }
  };

  const handleModalConfirm = async () => {
    setIsModalOpen(false);
    await joinCommunity();

    if (entity?.spec?.teamsChannelLink) {
      window.open(entity.spec.teamsChannelLink, '_blank');
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  if (isUserCommunityMember || isUserMaintainer) {
    return (
      <Button
        onClick={() => setIsLeaveDialogOpen(true)}
        variant="secondary"
        loading={membershipUpdateLoading}
        disabled={membershipUpdateLoading || isUserMaintainer}
      >
        You are a {isUserMaintainer ? 'maintainer' : 'member'} of this community
      </Button>
    );
  }

  return (
    <>
      <Button
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleJoinClick}
        variant="primary"
        loading={membershipUpdateLoading}
        disabled={membershipUpdateLoading}
      >
        Join community
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleModalCancel}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={styles.joinModalContent}>
          <div className={styles.joinTitleGrp}>
            <Typography variant="subtitle2" className={styles.title}>
              Teams Channel
            </Typography>
            <CloseOutlined
              onClick={handleModalCancel}
              className={styles.joinCloseBtn}
            />
          </div>
          <div className={styles.joinBody}>
            <Typography variant="body1">
              Do you want to join the Teams channel for this community?
            </Typography>
          </div>
          <Grid container justifyContent="space-between" style={{ padding: 0 }}>
            <Grid item>
              <Button
                variant="destructive"
                onClick={handleModalCancel}
                disabled={membershipUpdateLoading}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ marginLeft: '1rem' }}
                variant="secondary"
                onClick={() => void handleModalConfirm()}
                disabled={membershipUpdateLoading}
              >
                No, Thanks
              </Button>
              <Button
                style={{ marginLeft: '1rem' }}
                variant="primary"
                onClick={() => void handleModalConfirm()}
                disabled={membershipUpdateLoading}
              >
                Yes, Join
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
