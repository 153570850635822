/* tslint:disable */
/* eslint-disable */
/**
 * AzureLandingZone
 * An API for exposing Azure Landing Zone functionality and data sets.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: azurelandingzone@o365.corp.LEGO.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties } from './PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties';
import {
  PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesFromJSON,
  PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesFromJSONTyped,
  PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesToJSON,
} from './PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties';

/**
 * An object specifying an endpoint (e.g., a MS Teams channel's email address) where a team can be contacted. This endpoint will be used for an important communication regarding the subscription.
 * @export
 * @interface PostSubscriptionsRequestNotificationEndpointsInner
 */
export interface PostSubscriptionsRequestNotificationEndpointsInner {
  /**
   * The type of an endpoint.
   * @type {string}
   * @memberof PostSubscriptionsRequestNotificationEndpointsInner
   */
  endpointType: PostSubscriptionsRequestNotificationEndpointsInnerEndpointTypeEnum;
  /**
   *
   * @type {PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties}
   * @memberof PostSubscriptionsRequestNotificationEndpointsInner
   */
  endpointProperties: PostSubscriptionsRequestNotificationEndpointsInnerEndpointProperties;
}

/**
 * @export
 */
export const PostSubscriptionsRequestNotificationEndpointsInnerEndpointTypeEnum =
  {
    MSTeamsChannelEmail: 'MSTeamsChannelEmail',
    MSOffice365Group: 'MSOffice365Group',
  } as const;
export type PostSubscriptionsRequestNotificationEndpointsInnerEndpointTypeEnum =
  (typeof PostSubscriptionsRequestNotificationEndpointsInnerEndpointTypeEnum)[keyof typeof PostSubscriptionsRequestNotificationEndpointsInnerEndpointTypeEnum];

/**
 * Check if a given object implements the PostSubscriptionsRequestNotificationEndpointsInner interface.
 */
export function instanceOfPostSubscriptionsRequestNotificationEndpointsInner(
  value: object,
): value is PostSubscriptionsRequestNotificationEndpointsInner {
  if (!('endpointType' in value) || value['endpointType'] === undefined)
    return false;
  if (
    !('endpointProperties' in value) ||
    value['endpointProperties'] === undefined
  )
    return false;
  return true;
}

export function PostSubscriptionsRequestNotificationEndpointsInnerFromJSON(
  json: any,
): PostSubscriptionsRequestNotificationEndpointsInner {
  return PostSubscriptionsRequestNotificationEndpointsInnerFromJSONTyped(
    json,
    false,
  );
}

export function PostSubscriptionsRequestNotificationEndpointsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostSubscriptionsRequestNotificationEndpointsInner {
  if (json == null) {
    return json;
  }
  return {
    endpointType: json['endpointType'],
    endpointProperties:
      PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesFromJSON(
        json['endpointProperties'],
      ),
  };
}

export function PostSubscriptionsRequestNotificationEndpointsInnerToJSON(
  value?: PostSubscriptionsRequestNotificationEndpointsInner | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    endpointType: value['endpointType'],
    endpointProperties:
      PostSubscriptionsRequestNotificationEndpointsInnerEndpointPropertiesToJSON(
        value['endpointProperties'],
      ),
  };
}
