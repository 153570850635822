import { DiscoveryApi, FetchApi, OAuthApi } from '@backstage/core-plugin-api';
import {
  CommunityMembersResponse,
  CommunityV2,
  DiscussionsResponse,
  GithubIssueResponse,
  PullRequestResponse,
  TeamsChannelPostsResponse,
} from '@lego/plugin-baseplate-communities-common';
import { CommunitiesV2Api } from './CommunitiesV2Api';

export class CommunitiesV2Client implements CommunitiesV2Api {
  public constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
    private readonly microsoftAuth: OAuthApi,
  ) {}

  async getDiscussions(
    owner: string,
    repo: string,
  ): Promise<DiscussionsResponse> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/github/discussions?owner=${owner}&repo=${repo}`;

    const response = await this.fetchApi.fetch(baseUrl);
    if (!response.ok) {
      throw new Error(`Failed to get discussions. Status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  }

  async getPullRequests(
    owner: string,
    repo: string,
  ): Promise<PullRequestResponse> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/github/pull-requests?owner=${owner}&repo=${repo}`;

    const response = await this.fetchApi.fetch(baseUrl);
    if (!response.ok) {
      throw new Error(
        `Failed to get pull-requests. Status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  async getIssues(owner: string, repo: string): Promise<GithubIssueResponse> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/github/issues?owner=${owner}&repo=${repo}`;

    const response = await this.fetchApi.fetch(baseUrl);
    if (!response.ok) {
      throw new Error(`Failed to get issues. Status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  }

  public async createCommunity(community: CommunityV2): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const token = await this.microsoftAuth.getAccessToken();
    const baseUrl = `${await this.discoveryApi.getBaseUrl('communities-v2')}/`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-ms-token': token,
      },
      body: JSON.stringify(community),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to create community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async updateCommunity(community: CommunityV2): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl('communities-v2')}/${
      community.id
    }`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(community),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to update community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async getCommunityMemberEntityRefs(
    communityId: string,
  ): Promise<CommunityMembersResponse> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/${communityId}/members`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(
        `Failed to get community members with with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async joinCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/${communityId}/members`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ userEntityRef }),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to add member to community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async leaveCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/${communityId}/members`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ userEntityRef }),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to remove member from community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  async getTeamsChannelPosts(
    teamId: string,
    channelId: string,
  ): Promise<TeamsChannelPostsResponse> {
    const token = await this.microsoftAuth.getAccessToken();

    const response = await this.fetchApi.fetch(
      `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/messages?$expand=replies`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Failed to get community posts with status: ${response.status}`,
      );
    }

    const data = await response.json();
    const filteredMessages = data.value.filter(
      (message: any) => !message.body.content.includes('<systemEventMessage/>'),
    );

    const sortedMessages = filteredMessages.sort(
      (a: any, b: any) =>
        new Date(b.createdDateTime).getTime() -
        new Date(a.createdDateTime).getTime(),
    );

    return { ...data, value: sortedMessages.slice(0, 3) };
  }
}
