export const PLUGIN_ID = 'finops-scorecard';

export const SCORECARD_URL =
  'https://app-eu.apptio.com/cloudability?state=45196538-0aaf-46e9-8297-3e3f97bd8c95#/scorecards?dimension=category13&duration=ten-day';

export const SCORECARD_DOC =
  'https://help.apptio.com/en-us/cloudability/product/benchmark-your-cloud-spend-with-scorecards.html';

export const GETTING_STARTED =
  'https://baseplate.legogroup.io/docs/default/Component/finops-matters/#getting-started';

export const FEEDBACK_SUPPORT = 'https://legogroup.atlassian.net/browse/DR-513';

export const SCORECARD_TITLE = 'Cloud cost optimization';

export const SCORECARD_CAPTION = 'Spend wiser by following our guides';

export const STATUS = {
  LOADING: -2,
  NO_DATA: -1,
};
