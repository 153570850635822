import { Entity } from '@backstage/catalog-model';
import {
  DefaultEntityFilters,
  EntityFilter,
  EntityKindFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { AutocompleteDropdown } from '@lego/plugin-baseplate-core-components';
import React, { useEffect, useState } from 'react';
import { createDomainArray } from './utils';

export type DomainType = {
  name?: string;
  subDomain: { name?: string };
};

export class DomainFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const domain = entity.spec?.domain as DomainType;
    return this.values.some(value => value === domain?.name);
  }
  toQueryValue(): string[] {
    return this.values;
  }
}

export class SubdomainFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const subdomain = (entity.spec?.domain as DomainType)?.subDomain;
    return this.values.some(value => value === subdomain?.name);
  }
  toQueryValue(): string[] {
    return this.values;
  }
}

type CustomFilters = DefaultEntityFilters & {
  domain?: DomainFilter;
  subdomain?: SubdomainFilter;
};

export const handleDomainChangeLogic = (
  selectedDomain: string[],
  selectedSubdomain: string[],
  value: string[],
  domains: any[],
): { newSelectedDomain: string[]; newSelectedSubdomain: string[] } => {
  const removedDomains = selectedDomain.filter(
    domain => !value.includes(domain),
  );

  let newSelectedSubdomain = selectedSubdomain.filter(subdomain => {
    const belongingDomain = domains.find(domain =>
      domain.subDomains
        .map((subDomain: { name: string }) => subDomain.name)
        .includes(subdomain),
    );
    return belongingDomain && !removedDomains.includes(belongingDomain.name);
  });
  if (newSelectedSubdomain.length > 0) {
    const selectedDomainData = domains.find(domain =>
      value.includes(domain.name),
    );
    if (
      selectedDomainData &&
      !selectedDomainData.subDomains
        .map((subDomain: { name: string }) => subDomain.name)
        .includes(newSelectedSubdomain[0])
    ) {
      newSelectedSubdomain = [];
    }
  }

  return { newSelectedDomain: value, newSelectedSubdomain };
};

export const getSubDomainOptions = (
  selectedDomain: string[],
  domains: any[],
): string[] => {
  return selectedDomain.length > 0
    ? domains
        .filter(domain => selectedDomain.includes(domain.name))
        .flatMap(domain =>
          domain.subDomains.map(
            (subDomain: { name: string }) => subDomain.name,
          ),
        )
    : domains.flatMap(domain =>
        domain.subDomains.map((subDomain: { name: string }) => subDomain.name),
      );
};

export const CustomFilter = () => {
  const { backendEntities, updateFilters } = useEntityList<CustomFilters>();
  const domains = createDomainArray(backendEntities);
  const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
  const [selectedSubdomain, setSelectedSubdomain] = useState<string[]>([]);
  const domainOptions = domains.map(domain => domain.name);
  const subDomainOptions = getSubDomainOptions(selectedDomain, domains);

  useEffect(() => {
    updateFilters({
      kind: new EntityKindFilter('product'),
      domain: selectedDomain.length
        ? new DomainFilter(selectedDomain)
        : undefined,
      subdomain: selectedSubdomain.length
        ? new SubdomainFilter(selectedSubdomain)
        : undefined,
    });
  }, [updateFilters, selectedDomain, selectedSubdomain]);

  const handleDomainChange = (
    _event: React.ChangeEvent<object>,
    value: string[],
  ) => {
    const { newSelectedDomain, newSelectedSubdomain } = handleDomainChangeLogic(
      selectedDomain,
      selectedSubdomain,
      value,
      domains,
    );

    setSelectedSubdomain(newSelectedSubdomain);
    setSelectedDomain(newSelectedDomain);
  };

  const handleSubdomainChange = (
    _event: React.ChangeEvent<object>,
    value: string[],
  ) => {
    setSelectedSubdomain(value);
  };

  return (
    <>
      <AutocompleteDropdown
        label="Domain"
        options={domainOptions}
        value={selectedDomain}
        onChange={handleDomainChange}
        required
        multiple
      />
      <AutocompleteDropdown
        label="Subdomain"
        options={subDomainOptions}
        value={selectedSubdomain}
        onChange={handleSubdomainChange}
        required
        multiple
      />
    </>
  );
};
