import { useMemo } from 'react';
import { getColorScheme } from './colorScheme';
import { getChartTheme } from './theme';
import { ChartColorSchemes } from '../types';

export const useChartColors = (
  mode: 'light' | 'dark',
  colorScheme: ChartColorSchemes,
) => {
  const { theme, colors } = useMemo(
    () => ({
      theme: getChartTheme(mode),
      colors: getColorScheme(mode, colorScheme),
    }),
    [mode, colorScheme],
  );

  return { theme, colors };
};
