/* tslint:disable */
/* eslint-disable */
/**
 * AzureLandingZone
 * An API for exposing Azure Landing Zone functionality and data sets.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: azurelandingzone@o365.corp.LEGO.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PostSubscriptionsRequestNotificationEndpointsInner } from './PostSubscriptionsRequestNotificationEndpointsInner';
import {
  PostSubscriptionsRequestNotificationEndpointsInnerFromJSON,
  PostSubscriptionsRequestNotificationEndpointsInnerFromJSONTyped,
  PostSubscriptionsRequestNotificationEndpointsInnerToJSON,
} from './PostSubscriptionsRequestNotificationEndpointsInner';

/**
 *
 * @export
 * @interface PostSubscriptionsRequest
 */
export interface PostSubscriptionsRequest {
  /**
   * A detailed description of a business puprose.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  purpose: string;
  /**
   * A cost center associated with the subscription.
   * @type {number}
   * @memberof PostSubscriptionsRequest
   */
  costCenter: number;
  /**
   * The program TCO in order to categorize the group of subscriptions (e.g. "DCX" or "ERP"). Only pre-approved people can add it.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  programTCO?: PostSubscriptionsRequestProgramTCOEnum;
  /**
   * An application ID from the [Architecture Gateway](https://lego.leanix.net/ArchitectureGateway/inventory) in the form _APP-XXXXX_.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  application: string;
  /**
   * Environment of the subscription.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  environment: PostSubscriptionsRequestEnvironmentEnum;
  /**
   * A monthly budget in DKK associated with the subscription.
   * @type {number}
   * @memberof PostSubscriptionsRequest
   */
  monthlyBudget: number;
  /**
   * An email address of a person who approved the specified budget.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  budgetApprover: string;
  /**
   *
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  requestingUser?: string;
  /**
   * (DON'T SPECIFIY!) Intended to be specified only by the Cloud Enablement team. A subscription ID should be provided only during automated subscription migration carried by the Cloud Enablement team.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  subscriptionId?: string;
  /**
   * An email address of a technical responsible person.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  techResponsible: string;
  /**
   * An email address of an subscription owner.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  subscriptionOwner: string;
  /**
   * One of the two supported Microsoft Azure Offer numbers - Microsoft Azure Enterprise (MS-AZR-0017P) or Enterprise Dev/Test (MS-AZR-0148P). Note that only active Visual Studio subscribers with standard subscriptions can use the Azure resources running within an Enterprise Dev/Test subscription. For more information consult external documentation.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  azureBillingOfferId: PostSubscriptionsRequestAzureBillingOfferIdEnum;
  /**
   * Name of the new subscription. Must follow the format: _LEGO-{SubscriptionName}-{Environment}_. A length of the whole name can only be up to 41 characters long.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  finalSubscriptionName: string;
  /**
   * A list of notification endpoints.
   * @type {Array<PostSubscriptionsRequestNotificationEndpointsInner>}
   * @memberof PostSubscriptionsRequest
   */
  notificationEndpoints?: Array<PostSubscriptionsRequestNotificationEndpointsInner>;
  /**
   * An email address of an alternative technical responsible person.
   * @type {string}
   * @memberof PostSubscriptionsRequest
   */
  alternativeTechResponsible: string;
}

/**
 * @export
 */
export const PostSubscriptionsRequestProgramTCOEnum = {
  DCX: 'DCX',
  ERP: 'ERP',
} as const;
export type PostSubscriptionsRequestProgramTCOEnum =
  (typeof PostSubscriptionsRequestProgramTCOEnum)[keyof typeof PostSubscriptionsRequestProgramTCOEnum];

/**
 * @export
 */
export const PostSubscriptionsRequestEnvironmentEnum = {
  Sandbox: 'Sandbox',
  Dev: 'Dev',
  Test: 'Test',
  QA: 'QA',
  UAT: 'UAT',
  Staging: 'Staging',
  Prod: 'Prod',
} as const;
export type PostSubscriptionsRequestEnvironmentEnum =
  (typeof PostSubscriptionsRequestEnvironmentEnum)[keyof typeof PostSubscriptionsRequestEnvironmentEnum];

/**
 * @export
 */
export const PostSubscriptionsRequestAzureBillingOfferIdEnum = {
  _0017P: 'MS-AZR-0017P',
  _0148P: 'MS-AZR-0148P',
} as const;
export type PostSubscriptionsRequestAzureBillingOfferIdEnum =
  (typeof PostSubscriptionsRequestAzureBillingOfferIdEnum)[keyof typeof PostSubscriptionsRequestAzureBillingOfferIdEnum];

/**
 * Check if a given object implements the PostSubscriptionsRequest interface.
 */
export function instanceOfPostSubscriptionsRequest(
  value: object,
): value is PostSubscriptionsRequest {
  if (!('purpose' in value) || value['purpose'] === undefined) return false;
  if (!('costCenter' in value) || value['costCenter'] === undefined)
    return false;
  if (!('application' in value) || value['application'] === undefined)
    return false;
  if (!('environment' in value) || value['environment'] === undefined)
    return false;
  if (!('monthlyBudget' in value) || value['monthlyBudget'] === undefined)
    return false;
  if (!('budgetApprover' in value) || value['budgetApprover'] === undefined)
    return false;
  if (!('techResponsible' in value) || value['techResponsible'] === undefined)
    return false;
  if (
    !('subscriptionOwner' in value) ||
    value['subscriptionOwner'] === undefined
  )
    return false;
  if (
    !('azureBillingOfferId' in value) ||
    value['azureBillingOfferId'] === undefined
  )
    return false;
  if (
    !('finalSubscriptionName' in value) ||
    value['finalSubscriptionName'] === undefined
  )
    return false;
  if (
    !('alternativeTechResponsible' in value) ||
    value['alternativeTechResponsible'] === undefined
  )
    return false;
  return true;
}

export function PostSubscriptionsRequestFromJSON(
  json: any,
): PostSubscriptionsRequest {
  return PostSubscriptionsRequestFromJSONTyped(json, false);
}

export function PostSubscriptionsRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostSubscriptionsRequest {
  if (json == null) {
    return json;
  }
  return {
    purpose: json['purpose'],
    costCenter: json['costCenter'],
    programTCO: json['programTCO'] == null ? undefined : json['programTCO'],
    application: json['application'],
    environment: json['environment'],
    monthlyBudget: json['monthlyBudget'],
    budgetApprover: json['budgetApprover'],
    requestingUser:
      json['requestingUser'] == null ? undefined : json['requestingUser'],
    subscriptionId:
      json['subscriptionId'] == null ? undefined : json['subscriptionId'],
    techResponsible: json['techResponsible'],
    subscriptionOwner: json['subscriptionOwner'],
    azureBillingOfferId: json['azureBillingOfferId'],
    finalSubscriptionName: json['finalSubscriptionName'],
    notificationEndpoints:
      json['notificationEndpoints'] == null
        ? undefined
        : (json['notificationEndpoints'] as Array<any>).map(
            PostSubscriptionsRequestNotificationEndpointsInnerFromJSON,
          ),
    alternativeTechResponsible: json['alternativeTechResponsible'],
  };
}

export function PostSubscriptionsRequestToJSON(
  value?: PostSubscriptionsRequest | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    purpose: value['purpose'],
    costCenter: value['costCenter'],
    programTCO: value['programTCO'],
    application: value['application'],
    environment: value['environment'],
    monthlyBudget: value['monthlyBudget'],
    budgetApprover: value['budgetApprover'],
    requestingUser: value['requestingUser'],
    subscriptionId: value['subscriptionId'],
    techResponsible: value['techResponsible'],
    subscriptionOwner: value['subscriptionOwner'],
    azureBillingOfferId: value['azureBillingOfferId'],
    finalSubscriptionName: value['finalSubscriptionName'],
    notificationEndpoints:
      value['notificationEndpoints'] == null
        ? undefined
        : (value['notificationEndpoints'] as Array<any>).map(
            PostSubscriptionsRequestNotificationEndpointsInnerToJSON,
          ),
    alternativeTechResponsible: value['alternativeTechResponsible'],
  };
}
