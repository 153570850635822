import {
  ReviewStepProps,
  TemplateParameterSchema,
} from '@backstage/plugin-scaffolder-react';
import {
  Button,
  FormStepTitle,
  SummaryTable,
} from '@lego/plugin-baseplate-core-components';
import { Box, Typography } from '@material-ui/core';
import { Draft07 as JSONSchema } from 'json-schema-library';
import React from 'react';

export const ReviewStepComponent = ({
  disableButtons,
  formData,
  handleBack,
  handleCreate,
  steps,
  manifest,
}: ReviewStepProps & {
  manifest: TemplateParameterSchema;
}) => {
  const submitLabel =
    manifest?.presentation?.buttonLabels?.createButtonText ?? 'Create';

  const reviewData = Object.fromEntries(
    Object.entries(formData)
      .map(([key, originalValue]) => {
        let displayValue = originalValue;
        for (const step of steps) {
          const parsedSchema = new JSONSchema(step.mergedSchema);

          const definitionInSchema = parsedSchema.getSchema({
            pointer: `#/${key}`,
            data: formData,
          });

          const reviewOptions = definitionInSchema?.['ui:review'];
          const shouldHide = reviewOptions?.hide;

          if (shouldHide) {
            return [];
          }

          if (definitionInSchema) {
            const backstageReviewOptions =
              definitionInSchema['ui:backstage']?.review;

            if (backstageReviewOptions) {
              if (backstageReviewOptions.mask) {
                return [key, backstageReviewOptions.mask];
              }
              if (backstageReviewOptions.show === false) {
                return [];
              }
            }

            if (definitionInSchema['ui:widget'] === 'password') {
              return [key, '******'];
            }

            if (definitionInSchema.enum && definitionInSchema.enumNames) {
              return [
                key,
                definitionInSchema.enumNames[
                  definitionInSchema.enum.indexOf(originalValue)
                ] || originalValue,
              ];
            }
          }
        }

        // Check if displayValue is an object or an array and convert it to a string
        if (typeof displayValue === 'object' && displayValue !== null) {
          if (Array.isArray(displayValue)) {
            displayValue = displayValue
              .map(v => {
                if (typeof v === 'object' && v !== null) {
                  return Object.entries(v)
                    .map(([k, value]) => `${k}: ${value as string}`)
                    .join(' - ');
                }
                return v;
              })
              .join('\n');
          } else {
            displayValue = JSON.stringify(displayValue, null, 2);
          }
        }

        if (
          displayValue === undefined ||
          displayValue === null ||
          displayValue === ''
        ) {
          return [key, 'N/A'];
        }

        return [key, displayValue];
      })
      .filter(prop => prop.length > 0),
  ) as Record<string, unknown>;

  return (
    <Box className="narrow-layout">
      <FormStepTitle
        formTitle={manifest.title}
        stepTitle="Summary"
        step={steps.length + 1}
      />
      <Typography variant="body1">
        Please review the following values. If everything looks good, click the{' '}
        "{submitLabel}" button.
      </Typography>
      <SummaryTable
        values={Object.keys(reviewData).map(e => {
          return {
            label: e,
            value: reviewData[e],
          };
        })}
      />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'flex-end',
          paddingTop: '1rem',
        }}
      >
        <Button
          onClick={handleBack}
          disabled={disableButtons}
          variant="secondary"
          title="Back"
        >
          Back
        </Button>
        <Button
          onClick={handleCreate}
          disabled={disableButtons}
          variant="primary"
          title={submitLabel}
          trackingAttributes={{
            key: 'template-submit-button',
            subject: manifest.title,
          }}
        >
          {submitLabel}
        </Button>
      </div>
    </Box>
  );
};
