export const environments = [
  { label: 'sandbox', value: 'sandbox' },
  { label: 'test', value: 'test' },
  { label: 'dev', value: 'dev' },
  { label: 'qa', value: 'qa' },
  { label: 'uat', value: 'uat' },
  { label: 'staging', value: 'staging' },
  { label: 'prod', value: 'prod' },
] as const;

export type CloudEnvironment = (typeof environments)[number]['value'];

export type Vendor = 'Azure' | 'AWS' | 'AWS China';

export interface FormValues {
  vendor: Vendor;
  name: string;
  environment: CloudEnvironment;
  purpose: string;
  monthlyBudget: number;
  budgetAlertThreshold1: number;
  budgetAlertThreshold2: number;
  budgetAlertThreshold3: number;
  budgetAlertThreshold1Type: 'forecast' | 'actual';
  budgetAlertThreshold2Type: 'forecast' | 'actual';
  budgetAlertThreshold3Type: 'forecast' | 'actual';
  applicationId: string;
  applicationName: string;
  applicationRef?: string;
  applicationWBSNumber?: string;
  owner: string;
  ownerRef?: string;
  backupContact: string;
  backupContactRef?: string;
  budgetApprover: string;
  budgetApproverRef?: string;
  notificationEndpoint: string;
}

export interface StepNavigationProps {
  activeStepInt: number;
  isFormSubmitted: boolean;
}

export interface StepProps {
  values: FormValues;
  updateValue: (field: keyof FormValues, value: any) => void;
  productId: string;
}
