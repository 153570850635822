import React, { useMemo, useState } from 'react';
import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  BaseplateMarkdownContent,
  Button,
  Card,
  EntityLink,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import { type ReactElement } from 'react';
import { getWorkspaceRefFromNamespace } from './getWorkspaceRefFromNamespace';
import { IntegrationApiCallout } from './IntegrationApiCallout';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';

export interface AboutCardProps {
  entity: Entity;
  description?: string;
}

const useStyles = baseplateStyles(() => ({
  relations: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0',
    gap: '1rem',
  },
}));

const DESCRIPTION_MAX_LENGTH = 1500;

export function AboutCard({
  entity,
  description = '',
}: AboutCardProps): ReactElement {
  const classes = useStyles();
  const [showMore, setShowMore] = useState(false);

  const trimmedDescription = useMemo(() => {
    if (description.length > DESCRIPTION_MAX_LENGTH)
      return showMore
        ? description
        : description.slice(0, DESCRIPTION_MAX_LENGTH) + '...';
    return description;
  }, [entity, showMore]);

  const RelationItem = ({
    label,
    relation,
    kind,
  }: {
    label: string;
    relation: string;
    kind?: string;
  }) => {
    const relatedEntity = (useRelatedEntities(entity, {
      kind,
      type: relation,
    }).entities ?? [])[0];

    return (
      <div>
        <Typography variant="overline" color="secondary">
          {label}
        </Typography>
        <EntityLink entity={relatedEntity} variant="link" />
      </div>
    );
  };

  return (
    <Card title="About">
      <BaseplateMarkdownContent content={trimmedDescription} />
      {description.length > DESCRIPTION_MAX_LENGTH && (
        <Button
          variant="secondary"
          size="small"
          onClick={() => setShowMore(!showMore)}
          children={!showMore ? 'Show more' : 'Show less'}
        />
      )}
      {entity.kind.toLocaleLowerCase() === 'api' &&
        entity.spec?.environment === AMMA_ENVIRONMENT.INTEGRATION && (
          <IntegrationApiCallout />
        )}
      <div className={classes.relations}>
        <RelationItem label="Owner" relation={RELATION_OWNED_BY} />
        <RelationItem
          label="Part of"
          relation={RELATION_PART_OF}
          kind="application"
        />
        <RelationItem
          label="Identity"
          relation={RELATION_PART_OF}
          kind="identity"
        />
        <div>
          <Typography variant="overline" color="secondary">
            Workspace
          </Typography>
          <EntityLink
            entityRef={`workspace:default/${getWorkspaceRefFromNamespace(
              entity.metadata.namespace,
            )}`}
            variant="link"
          />
        </div>
      </div>
    </Card>
  );
}
