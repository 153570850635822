import { AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum } from '@lego/plugin-baseplate-cloud-landing-zones-sdk/src/aws';
import { FormValues, CloudEnvironment, environments } from '../utils/types';
import { aws, azure } from '@lego/plugin-baseplate-cloud-landing-zones-sdk';

const extractEndpointValue = (notificationEndpoint: string) => ({
  endpointName: notificationEndpoint.substring(
    0, // From the beginning of the string
    notificationEndpoint.indexOf('<') - 1, // Until the '<' sign, minus the space
  ),
  endpointValue: notificationEndpoint.substring(
    notificationEndpoint.indexOf('<') + 1,
    notificationEndpoint.indexOf('>'),
  ),
});

export const mapValuesToAzurePayload = (
  values: FormValues,
): azure.PostSubscriptionsRequest => {
  const mapToEnvironment = (
    environment: CloudEnvironment,
  ): azure.PostSubscriptionsRequestEnvironmentEnum => {
    switch (environment?.toLocaleLowerCase()) {
      case 'dev':
        return 'Dev';
      case 'sandbox':
        return 'Sandbox';
      case 'prod':
        return 'Prod';
      case 'test':
        return 'Test';
      case 'uat':
        return 'UAT';
      case 'qa':
        return 'QA';
      case 'staging':
        return 'Staging';
      default:
        throw new Error(
          `E4657: Unknown environment ${environment}. Must be one of ${environments
            .map(x => x.value)
            .join(', ')}`,
        );
    }
  };

  const environment = mapToEnvironment(values.environment);

  return {
    purpose: values.purpose,
    costCenter: 100_000, //
    programTCO: undefined,
    application: values.applicationId,
    environment,
    monthlyBudget: values.monthlyBudget,
    budgetApprover: values.budgetApprover ?? '',
    techResponsible: values.owner ?? '',
    subscriptionOwner: values.owner ?? '',
    azureBillingOfferId:
      azure.PostSubscriptionsRequestAzureBillingOfferIdEnum._0017P,
    finalSubscriptionName: `LEGO-${values.name}-${environment}`,
    notificationEndpoints: [
      {
        endpointType:
          azure
            .PostSubscriptionsRequestNotificationEndpointsInnerEndpointTypeEnum
            .MSTeamsChannelEmail,
        endpointProperties: extractEndpointValue(values.notificationEndpoint),
      },
    ],
    alternativeTechResponsible: values.backupContact ?? '',
  };
};

export const mapValuesToAWSPayload = (
  values: FormValues,
): aws.AwsAccountCreationRequestBody => {
  const { vendor, ...rest } = values;

  const mapBugdetToEnum = (alertType: 'forecast' | 'actual') =>
    alertType === 'forecast'
      ? AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum.FORECASTED
      : AwsAccountCreationRequestBodyBudgetAlertsInnerNotificationTypeEnum.ACTUAL;

  const { endpointName, endpointValue } = extractEndpointValue(
    rest.notificationEndpoint,
  );

  return {
    accountName: rest.name,
    environment: rest.environment,
    accountOwner: rest.owner ?? '',
    awsPartition: vendor === 'AWS China' ? 'China' : 'Standard',
    budgetAlerts: [
      {
        threshold: rest.budgetAlertThreshold1,
        notificationType: rest.budgetAlertThreshold1Type,
      },
      {
        threshold: rest.budgetAlertThreshold2,
        notificationType: rest.budgetAlertThreshold2Type,
      },
      {
        threshold: rest.budgetAlertThreshold3,
        notificationType: rest.budgetAlertThreshold3Type,
      },
    ].map(budget => ({
      threshold: budget.threshold,
      notificationType: mapBugdetToEnum(budget.notificationType),
    })) as unknown as Set<aws.AwsAccountCreationRequestBodyBudgetAlertsInner>, // Set can not be JSON.stringified
    monthlyBudget: rest.monthlyBudget,
    budgetApprover: rest.budgetApprover ?? '',
    businessPurpose: rest.purpose ?? '',
    techResponsible: rest.owner ?? '',
    notificationEndpoints: [
      {
        endpointType:
          aws
            .AwsAccountCreationRequestBodyNotificationEndpointsInnerEndpointTypeEnum
            .MSTeamsChannelEmail,
        endpointProperties: {
          channelName: endpointName,
          address: endpointValue,
        },
      },
    ] as unknown as Set<aws.AwsAccountCreationRequestBodyNotificationEndpointsInner>, // Set can not be JSON.stringified
    alternativeTechResponsible: rest.backupContact ?? '',
    architectureGatewayApplicationId: rest.applicationId,
    architectureGatewayApplicationName: rest.applicationName,
    costCenter: 100_000, //
    wbsNumber: rest.applicationWBSNumber, // TODO: Derive from product entitiy
  };
};
