import { parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { AutocompleteDropdown } from '../autocomplete-dropdown/AutocompleteDropdown';
import { BaseplateEntityDropdownProps } from './EntityDropdownProps.type';
import { getName, getTitle } from './EntityDropdown.utils';

/**
 * A form control component that displays a dropdown menu of entities. The list of entities is
 * filtered by the `kind` parameter, which defaults to `Product`.
 *
 * @param {Object} props - The component's props.
 */
export const EntityDropdown = (props: BaseplateEntityDropdownProps) => {
  const {
    helperText,
    handleChange,
    placeholder,
    kind = 'Product',
    type,
    required,
    value,
    errorMessage,
    filter,
  } = props;

  const catalogApi = useApi(catalogApiRef);

  const label = props.label ?? kind;

  const {
    loading,
    value: entities,
    error,
  } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: { ...(filter ?? {}), kind },
    });

    if (type) {
      return items.filter(entity => entity.spec?.type === type);
    }
    return items;
  }, [kind]);

  return (
    <AutocompleteDropdown
      label={label}
      onInputChange={(_event, _newInputValue, reason) => {
        if (reason === 'clear') {
          handleChange(undefined as any);
        }
      }}
      placeholder={placeholder}
      disabled={loading || !entities || entities.length === 0}
      loading={loading}
      helperText={helperText}
      error={Boolean(error) || !!errorMessage}
      errorText={errorMessage ?? `Error loading ${kind}s`}
      required={required}
      onChange={(_, v) => v && handleChange(v)}
      getOptionLabel={option => getTitle(option)}
      value={
        (value &&
          (entities || []).find(
            entity =>
              getName(entity).toLocaleLowerCase() ===
              parseEntityRef(value).name.toLocaleLowerCase(),
          )) ??
        null
      }
      options={entities || []}
      autoSelect
    />
  );
};
