import { createApiRef } from '@backstage/core-plugin-api';
import {
  CommunityMembersResponse,
  CommunityV2,
  GithubIssueResponse,
  PullRequestResponse,
  DiscussionsResponse,
  TeamsChannelPostsResponse,
} from '@lego/plugin-baseplate-communities-common';

export const communitiesV2ApiRef = createApiRef<CommunitiesV2Api>({
  id: 'plugin.communities-v2.apis',
});

export interface CommunitiesV2Api {
  createCommunity(community: CommunityV2): Promise<CommunityV2>;
  updateCommunity(community: CommunityV2): Promise<CommunityV2>;
  joinCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2>;
  leaveCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2>;
  getCommunityMemberEntityRefs(
    communityId: string,
  ): Promise<CommunityMembersResponse>;
  getIssues(owner: string, repo: string): Promise<GithubIssueResponse>;
  getPullRequests(owner: string, repo: string): Promise<PullRequestResponse>;
  getDiscussions(owner: string, repo: string): Promise<DiscussionsResponse>;
  getTeamsChannelPosts(
    teamId: string,
    channelId: string,
  ): Promise<TeamsChannelPostsResponse>;
}
