import React from 'react';
import { FormStep } from '@lego/plugin-baseplate-core-components';
import { StepNavigationProps } from '../utils/types';

export const StepNavigation = ({
  activeStepInt,
  isFormSubmitted,
}: StepNavigationProps) => {
  return (
    <FormStep.Wrapper>
      {['Choose vendor', 'Account information', 'Budget', 'Summary'].map(
        (label, index) => {
          const stepNumber = index + 1;
          return (
            <FormStep.Item
              key={stepNumber}
              label={label}
              stepNumber={stepNumber}
              completed={stepNumber < activeStepInt || isFormSubmitted}
              active={activeStepInt === stepNumber && !isFormSubmitted}
              disabled={true}
            />
          );
        },
      )}
    </FormStep.Wrapper>
  );
};
